import { CardSection } from "./common/Card";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { DateRangePicker } from "./common/DateRangePicker";

import { ISODateRange } from "../ISODateRange";
import {DownloadCSVButton} from "./common/SubmitButton";
import { Result } from "../client/Result";


interface BillingExportProps {
    download: (range: ISODateRange) => Promise<Result<void>>
    className?: string
}

export const BillingExport = ({ download, className }: BillingExportProps) => {

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [dateRangeValid, setDateRangeValid] = useState(false)

    const [validated, setValidated] = useState(false);

    const [errorMessage, setErrorMessage] = useState<string>()

    async function handleSubmit(event) {

        const form = event.currentTarget
        setErrorMessage(undefined)
        event.preventDefault()
        setValidated(true)
        if (form.checkValidity() === true && dateRangeValid) {
            const submissionResult = await download(new ISODateRange(startDate, endDate))
            submissionResult.fold(
                ()  => undefined,
                err => setErrorMessage(`An error occurred - #ask-tech for support. ${err}`)
            )
        } else {
            form.reportValidity()
        }
    }

    return <CardSection className={className} header={'Billing Export'}>

        <Form noValidate validated={validated} >
            <DateRangePicker start={startDate} end={endDate} onEndChange={(end) => setEndDate((end))} onStartChange={(start) => setStartDate(start)} setErrorMessage={error => (setErrorMessage(error))} reportIsValid={valid => setDateRangeValid(valid)} />
            <DownloadCSVButton dataTestId='billingExportSubmitButton'
                disabled={!(startDate && endDate && (startDate <= endDate))}
                onSubmit={handleSubmit}/>
            {errorMessage &&
                <Alert data-testid='errorMessage' variant='danger'>{errorMessage}</Alert>}
        </Form>
    </CardSection>
}
