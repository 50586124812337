import {useNavigate} from "react-router-dom";
import * as React from "react";
import {useEffect} from "react";

export interface HomePageProps {
    redirectUrl: string
}

export const HomePage = ({redirectUrl}: HomePageProps) => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate(redirectUrl)
    })

    return <div/>
}
