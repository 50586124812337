import React, {createContext, useContext} from 'react';
import {DateTime} from "./time/DateTime";

// Create a context
const DateTimeContext: React.Context<() => DateTime> = createContext(()=> DateTime.now());
// Create a custom hook to access the context

export const useDateTimeContext = () => useContext(DateTimeContext);

// Create a provider component to provide the datetime value
export const DateTimeProvider = ({ children, datetime }) => (
    <DateTimeContext.Provider value={datetime}>{children}</DateTimeContext.Provider>
);