import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { App } from './App';

import { detectRuntimeEnvironment } from './runtimeEnvironmentDetection';

import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { IdentityProvider } from "./identityProvider";

library.add(faArrowUpRightFromSquare)
dom.watch()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);


const env = detectRuntimeEnvironment(
    window.location.href,
    'process' in window ? process.env as { [key: string]: string } : {}
);

/* eslint-disable-next-line @typescript-eslint/no-var-requires */
const config = require(`./environments/${env}.ts`).default;

root.render(
    <React.StrictMode>
        <IdentityProvider config={config}>
                <App config={config} />
        </IdentityProvider>
    </React.StrictMode>
);
