import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { AppRoute } from "../../Routes";
import { NavLink } from "react-router-dom";
import { Logo } from "./Logo";
import './sideBar.scss'
import { Button } from "react-bootstrap";
import { ContactUs, SignOutIcon } from "../icons/Icons";
import { useAuthContext } from "../../AuthContext";
import { useEntitlements } from "../../EntitlementProvider";
import { Permission } from "../../Permission";

export interface SideBarProps {
    routes: AppRoute[]
}

export const SideBar = ({ routes }: SideBarProps) => {

    const logout = useAuthContext()?.logout
    const contactUs = useEntitlements()?.has(Permission.CONTACT_US)

    const handleLogoutClick = () => {
        if (logout) {
            logout()
        }
    };

    const links = routes.map((route, index) =>
        <MenuItem className={'sidebar-link'} key={'sideBarLinks' + index} component={<NavLink to={route.path} />} data-testid={'side-bar-link-' + index}>
            {route.readableName}
        </MenuItem>)

    return <Sidebar className={'sidebar'} backgroundColor="#093A1D">
        <div className={'sidebar-logo'}>
            <Logo />
        </div>
        <Menu>
            {links}
        </Menu>
        {contactUs && <div className={'sidebar-footer'}>
            <Button className={'sidebar-footer-contact-us'} data-testid={`contact-us-button`} >
                <ContactUs size={'2x'} /> <a className={'sidebar-footer-contact-us__text'} href="mailto:operations@oaktreepower.com">Contact Us</a>
            </Button>
        </div>}
        {logout &&
            <div className={'sidebar-footer'}>
                <Button className={'sidebar-footer-logout'} data-testid={`logout-button`} onClick={handleLogoutClick}>
                    <SignOutIcon size={'2x'} /> <span className={'sidebar-footer-logout__text'}>logout</span>
                </Button>
            </div>}
    </Sidebar>
}