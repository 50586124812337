import { TextField } from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import Box from "@mui/material/Box";
import { CrossIcon, TickIcon } from "../icons/Icons";
import Button from "react-bootstrap/Button";
import React, { useState } from "react";
import MultiSelect, { Select, SelectOption } from "./MuiSelect";
import { DateTime } from "../../time/DateTime";
import { useNavigate } from "react-router-dom";
import { UserAlert } from "./UserAlert";
import { Result } from "../../client/Result"
import { DateTime as LuxonDateTime } from 'luxon'

export type FieldType = 'number' | 'string' | 'multiselect' | 'date' | 'datetime' | 'select'

export interface FieldProp<T> {
    key: keyof T
    label: string
    type: FieldType
    required?: boolean
    disabled?: boolean
}

export interface MultiFieldProp<T> extends FieldProp<T> {
    options: SelectOption[]
}

interface CrudEditProps<T extends object> {
    data?: T
    fields: (FieldProp<T> | MultiFieldProp<T>)[]
    onSubmit: (data: Partial<T>) => Promise<Result<null>>
    redirectPath: string
}

export function assertExhaustive(
    value: never,
    message = 'Reached unexpected case in exhaustive switch'
): never {
    throw new Error(message);
}



export function CrudForm<T extends object>({ data, fields, onSubmit, redirectPath }: CrudEditProps<T>) {

    const [state, setState] = useState<Partial<T>>(data ? data : {})
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState<string>('')
    const nav = useNavigate()

    function onChange(fieldName, newValue) {
        setState(prev => ({ ...prev, [fieldName]: newValue }))
    }
    const handleSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault()
        const result = await onSubmit(state)
        result.fold(
            () => nav(redirectPath, { state: { userAlert: { message: 'Submitted Successfully ', type: 'success' } } }),
            message => {
                setShowAlert(true);
                setAlertMessage(message)
            })
    }

    const resolve = (e: LuxonDateTime<boolean> | null) => e ? DateTime.fromRaw(e, false) : null

    const f: React.JSX.Element[] = fields.map(f => {
        const datum: T[keyof T] | undefined = state?.[f.key]
        switch (f.type) {
            case 'number':
                return <TextField data-testid={`numberfield-${f.key.toString()}`} onChange={(e) => onChange(f.key, e.target.value)} type={'number'}
                    label={f.label} variant="outlined"
                    value={datum as string ?? ''} name={f.label} required={f.required} disabled={f.disabled}></TextField>
            case 'string':
                return <TextField data-testid={`textfield-${f.key.toString()}`} onChange={(e) => onChange(f.key, e.target.value)} required={f.required} disabled={f.disabled}
                    label={f.label} variant="outlined" value={datum as string ?? '' }
                    name={f.label} />
            case 'multiselect': {
                const options = f['options']
                const initialValue = options?.length > 0 ? datum as SelectOption[] | undefined : undefined
                return <MultiSelect data-testid={`multiselect-${f.key.toString()}`} initialValue={initialValue} label={f.label} required={f.required} disabled={f.disabled}
                    onChange={(e) => onChange(f.key, e)} options={f['options']} minWidth={250} />
            }
            case 'select': {
                const options = f['options']
                const initialValue = options?.length > 0 ? datum as SelectOption | undefined : undefined
                return <Select data-testid={`select-${f.key.toString()}`} initialValue={initialValue} label={f.label} required={f.required} disabled={f.disabled}
                    onChange={(e) => onChange(f.key, e)} options={options} minWidth={250} />
            }
            case 'date':
                return <DatePicker data-testid={`datePicker-${f.label}`} value={(datum as DateTime | undefined)?.raw() ?? null}
                    label={f.label} onChange={(e) => onChange(f.key, resolve(e))} disabled={f.disabled} />
            case 'datetime':
                return <DateTimePicker value={(datum as DateTime | undefined)?.raw() ?? null} label={f.label} onChange={(e) =>
                    onChange(f.key, resolve(e))} disabled={f.disabled} />
            default:
                assertExhaustive(f.type);
                break;
        }
    }).map((t, i) => <Box key={`key-${i}`} sx={{ m: 1 }}>{t}</Box>)

    return <>
        <form noValidate={false} autoComplete="off" onSubmit={handleSubmit}>
            <Box sx={{
                flexWrap: 'wrap',
                display: 'inline-flex',
                flexDirection: 'row',
            }}>{f}
            </Box>
            <ActionButtons redirectPath={redirectPath} nav={nav} />
            <UserAlert show={showAlert} setShow={setShowAlert} alertInfo={{ message: alertMessage, type: "error" }} />
        </form>
    </>
}


interface ActionButtonsProps {
    redirectPath: string
    nav
}

const ActionButtons = ({ redirectPath, nav }: ActionButtonsProps) => {

    return <><Box>
        <Button variant="primary" type="submit" data-testid={'submit-crud-form'}
        ><TickIcon /> Save</Button>
        <Button data-testid='cancelButton' variant='outline-secondary' onClick={() => {
            nav(redirectPath)
        }}>
            <CrossIcon /> Cancel</Button>
    </Box>
    </>
}
