import {DeviceClient} from "../../client/DeviceClient";
import {DevicePreparation} from "../DevicePreparation";
import * as React from "react";

export interface DevicePreparationPageProps {
    deviceClient: DeviceClient
}

export const DevicePreparationPage = ({deviceClient}: DevicePreparationPageProps) => {
    return <div>
        <DevicePreparation deviceClient={deviceClient} />
    </div>
}
