import {Building} from "./BuildingStatus";
import MultiSelect, {SelectOption} from "./common/MuiSelect";

export interface MultipleBuildingInputProps {
    buildingsSource: Building[]
    onChange: (bs: Building[]) => void
}

export const MultipleBuildingInput = ({buildingsSource, onChange}: MultipleBuildingInputProps) => {
    const result = buildingsSource.reduce(function(map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});


    function handleBuildingChange(selectedList: SelectOption[]) {
        // Beware: When the elements selection change, the Multiselect component reuses the array passed here as
        // selectedList. Here we pass through a new array every time, because the caller typically use the onChange
        // to update a React state, but React doesn't detect changes to the membership of an array when the array
        // is the same even if the members change.

        const multiSelectOptions: Building[] = selectedList.map(b => {
           return result[b.id]
        }) ;
        onChange([...multiSelectOptions])
    }
    return <MultiSelect options={buildingsSource.map(it=>({id:`${it.id}`, label:it.name}))}  onChange={handleBuildingChange}  data-testid='bob' id='potato' label={'Buildings'}/>
}
