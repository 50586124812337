import React, { DependencyList, ReactElement, useEffect, useState } from 'react';
import { isArray } from 'lodash';
import {ErrorMessage} from "./ErrorMessage";
import {LoadingIndicator} from "./LoadingIndicatior";
import { Result } from '../../client/Result';
import {Entitlement} from "../../Entitlement";
import {useEntitlements} from "../../EntitlementProvider";

export type EffectDependency = unknown;
export type AsyncComponentProps<T> = {
    loaderFunction: () => Promise<T>;
    loaderInterval?: number;
    effectDependency?: EffectDependency | EffectDependency[];
    dataPropName: string;
    onData?: (data: T) => void;
    children: ReactElement
}

export function AsyncComponent<T>({ loaderFunction, loaderInterval, dataPropName, effectDependency, children, onData }: AsyncComponentProps<T>) {
    const [loading, setLoading] = useState<boolean>(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [data, setData] = useState<any>()
    const [error, setError] = useState<Error|null>()

    const fetchData = async () => {
        try {
            const fetchedData = await loaderFunction();
            onData?.(fetchedData);
            setData(fetchedData);
            setError(null);
        } catch (e) {
            setError(e)
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
        let interval

        if (loaderInterval) {
            interval = setInterval(fetchData, loaderInterval)
        }

        return () => {
            clearInterval(interval)
        }
    }, (isArray(effectDependency) ? effectDependency : [effectDependency]) as DependencyList)

    if (loading) {
        return <LoadingIndicator />
    } else if (error) {
        return <ErrorMessage error={error} />
    }
    return React.cloneElement(children, { [dataPropName]: data, requestDataRefresh: fetchData })
}

export type AsyncComponentPropsP<T> = {
    loaderFunction: () => Promise<Result<T>>;
    loaderInterval?: number;
    effectDependency?: EffectDependency | EffectDependency[];
    dataPropName: string;
    onData?: (data: T) => void;
    children: ReactElement
}
export function AsyncComponentP<T>({ loaderFunction, loaderInterval, dataPropName, effectDependency, children, onData }: AsyncComponentPropsP<T>) {
    const [loading, setLoading] = useState<boolean>(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [data, setData] = useState<any>()
    const [error, setError] = useState<Error|null>()

    const fetchData = async () => {
        try {
            const fetchedDataResult = await loaderFunction();
            
            fetchedDataResult.fold(
                fetchedData => {
                    onData?.(fetchedData);
                    setData(fetchedData);
                    setError(null);
                },
                errorMessage => {
                    setError(Error(`Got an error from the b/e ${errorMessage}`))
                }
            )
        } catch (e) {
            setError(e)
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
        let interval

        if (loaderInterval) {
            interval = setInterval(fetchData, loaderInterval)
        }

        return () => {
            clearInterval(interval)
        }
    }, (isArray(effectDependency) ? effectDependency : [effectDependency]) as DependencyList)

    if (loading) {
        return <LoadingIndicator />
    } else if (error) {
        return <ErrorMessage error={error} />
    }
    return React.cloneElement(children, { [dataPropName]: data, requestDataRefresh: fetchData })
}

export type AsyncComponentWithEntitlement<T> = {
    loaderFunction: (entitlement: Entitlement) => Promise<Result<T>>
} & Omit<AsyncComponentPropsP<T>, 'loaderFunction'>

export function AsyncComponentWithEntitlement<T>({ loaderFunction, loaderInterval, dataPropName, effectDependency, children, onData }: AsyncComponentWithEntitlement<T>) {
    const entitlements = useEntitlements()

    if(entitlements.isLoading()) return <LoadingIndicator/>

    const effectDeps = Array.isArray(effectDependency) ? [...effectDependency,entitlements] : [effectDependency, entitlements]
    return <AsyncComponentP loaderFunction={() => loaderFunction(entitlements)} dataPropName={dataPropName} effectDependency={effectDeps} children={children} onData={onData} loaderInterval={loaderInterval}/>
}