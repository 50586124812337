import {CrudForm, FieldProp, MultiFieldProp} from "./common/CrudForm";
import React from "react";
import {Organisation, Role, EitherUser, isFullUser} from "../client/UserClient";
import {SelectOption} from "./common/MuiSelect";
import {admin} from "../Routes";
import {Result} from "../client/Result";


export interface EditFormInitialData {
    roles: Role[],
    organisations: Organisation[]
    user:EitherUser
}

interface WrapperProp{
    initialData: EditFormInitialData
    onSubmit: (user: EitherUser) => Promise<Result<null>>
}

interface FormUser {
    queryableDataId: string
    name: string
    email: string
    roles: SelectOption[]
    organisation: SelectOption
}

export function EditUserForm({initialData, onSubmit} : WrapperProp){
    const {roles, user, organisations} = initialData

    function rolesToOptions(roles: Role[]): SelectOption[] {
        return roles.map(role => ({id: role.id, label: role.name}))
    }

    function organisationToOptions(organisations: Organisation[]): SelectOption[] {
        return organisations.map(organisation => ({id: organisation.id, label: organisation.name}))
    }

    function organisationToOption(organisation: Organisation): SelectOption {
        return {id: organisation.id, label: organisation.name}
    }

    function optionsToRoles(options: SelectOption[]): Role[] {
        return options.map(option => ({id: option.id, name: option.label}))
    }

    function optionsToOrganisation(option: SelectOption) : Organisation {
        return {id: option.id, name: option.label}
    }

    function handleSubmit(formUser: Partial<FormUser>): Promise<Result<null>> {
        if(isFullUser(user)){
            const {queryableDataId, name, email, roles, organisation}: Partial<FormUser> = formUser
            if (queryableDataId && name && email && roles && organisation) {
                return onSubmit({queryableDataId, name, email, roles: optionsToRoles(roles), organisation: optionsToOrganisation(organisation) })
            } else {
                throw new Error('Submitting user edit without original fields')
            }
        }else {
            const {queryableDataId, name, email}: Partial<FormUser> = formUser
            if (queryableDataId && name && email) {
                return onSubmit({queryableDataId, name, email})
            } else {
                throw new Error('Submitting user edit without original fields')
            }
        }

    }

    const adjusted: FormUser = (isFullUser(user) ?
            {...user, roles: rolesToOptions(user.roles), organisation: organisationToOption(user.organisation)} :
            user
    ) as FormUser

    const fullUser: (FieldProp<FormUser> | MultiFieldProp<FormUser>)[] = [
        {key: `name`, label: 'Name', type: 'string', required: true},
        {key: `email`, label: 'Email', type: 'string', required: true, disabled: true},
        {key: `roles`, label: 'Roles', type: 'multiselect', options: rolesToOptions(roles), required: true},
        {key: `organisation`, label: 'Organisation', type: 'select', options: organisationToOptions(organisations), required: true},
    ]

    const simpleUser: (FieldProp<FormUser> | MultiFieldProp<FormUser>)[] = [
        {key: `name`, label: 'Name', type: 'string', required: true},
        {key: `email`, label: 'Email', type: 'string', required: true, disabled: true},
    ]

    return <CrudForm redirectPath={admin.path} data={adjusted} fields={isFullUser(user)  ? fullUser: simpleUser} onSubmit={handleSubmit}/>
}
