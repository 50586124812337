import { useParams } from "react-router-dom";
import { Failure, Result } from "../../client/Result";
import { useEntitlements } from "../../EntitlementProvider";
import { CrudForm } from "../common/CrudForm";
import { meteringOptionalRoute } from "../../Routes";
import { BuildingClient, Tariff } from "../../client/BuildingClient";
import { useEffect, useState } from "react";
import { DateTime } from "../../time/DateTime";

interface EditTariffPageProps {
    buildingClient: BuildingClient
}

export const EditTariffPage = ({ buildingClient }: EditTariffPageProps) => {

    const { buildingId, tariffId } = useParams()
    const [tariff, setTariff] = useState<Tariff>();

    const entitlement = useEntitlements()

    useEffect(() => {
        if (tariffId) {
            buildingClient.retrieveTariff(tariffId).then(t => setTariff(t.fold(t => t, () => undefined)))
        } else {
            setTariff(undefined)
        }
    }, [tariffId, entitlement])

    type SubmitFunction = (params: {
        startDate: DateTime
        endDate: DateTime
        pricePerKWh: number
    }) => Promise<Result<null>>

    const onSubmit: SubmitFunction = ({ startDate, endDate, pricePerKWh }) => {
        if (tariff) {
            const body = {
                transmissionFeeType: tariff.transmissionFeeType!,
                startDate: startDate.toISODate(),
                endDate: endDate.toISODate(),
                pricePerKWh: Number(pricePerKWh),
                mpan: tariff.mpan!
            }

            return buildingClient.updateTariff(tariff.id.toString(), body)

        } else {
            return Promise.reject(new Failure("Tariff does not exist"))
        }
    }

    return tariff && buildingId ?
        <CrudForm redirectPath={meteringOptionalRoute.withParams(Number(buildingId))} data={tariff} fields={[
            { key: `startDate`, label: 'Start Date', type: 'date', required: true },
            { key: `endDate`, label: 'End Date', type: 'date', required: true },
            { key: `pricePerKWh`, label: 'Price £/KWH', type: 'number', required: true },
        ]} onSubmit={onSubmit} /> : <></>

}
