import {createContext, PropsWithChildren, useContext} from 'react';
import {Entitlement} from './Entitlement';

export interface UserInfo {
    token: string,
    entitlement: Entitlement
}

export type Auth = {
    userInfo: () => Promise<UserInfo>
    logout?: () => void
}


const AuthContext = createContext<Auth | undefined>(undefined);

export const useAuthContext = () => useContext(AuthContext);

interface AuthContextProviderProps extends PropsWithChildren {
    auth: Auth
}

export const AuthContextProvider = ({auth, children}: AuthContextProviderProps) => {
    const handleFailedPromise: () => Promise<UserInfo> = () => auth.userInfo().catch(error => {
        console.log(`auth promise unresolved with error ${error} so for now you will be denied access`)
        return Promise.resolve({token: '', entitlement: Entitlement.loser})
    })

    return <AuthContext.Provider
        value={{userInfo: handleFailedPromise, logout: auth.logout}}>{children}</AuthContext.Provider>
}
