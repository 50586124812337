const ENVIRONMENTS = [
    'prod',
    'uat',
    'local',
    'test'
] as const;

type EnvName = typeof ENVIRONMENTS[number]

const getEnvironmentFromEnv = (env: { [key: string]: string }): EnvName | null => {
    if ('JEST_WORKER_ID' in env) {
        return 'test';
    } else {
        return null;
    }
};

const getEnvironmentFromUrl = (url: string): EnvName | null => {
    const {hostname} = new URL(url);
    switch (hostname.split('.')[0]) {
        case 'localhost':
            return 'local';
        case 'uat':
            return 'uat';
        case 'www':
        case 'oaktreepower':
            return 'prod';
        default:
            return null;
    }

};

export const detectRuntimeEnvironment = (
    url: string,
    env: { [key: string]: string }
): EnvName => getEnvironmentFromEnv(env) || getEnvironmentFromUrl(url) || 'test';
