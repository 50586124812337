import { CardSection } from "./common/Card";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { DateRangePicker } from "./common/DateRangePicker";

import { LondonDateRange } from "../ISODateRange";
import {DownloadCSVButton} from "./common/SubmitButton";
import { Result } from "../client/Result";
import { BuildingInput } from "./BuildingInput";
import { Building } from "./BuildingStatus";
import { DateRange } from "../client/DataClient";


interface UKPNSettlementExportProps {
    download: (range: DateRange, building: Building) => Promise<Result<void>>
    buildings: Building[],
    className?: string
}

export const UKPNSettlementExport = ({ download, buildings, className }: UKPNSettlementExportProps) => {

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [dateRangeValid, setDateRangeValid] = useState(false)
    const [building, setBuilding] = useState<Building>()

    const [validated, setValidated] = useState(false);

    const [errorMessage, setErrorMessage] = useState<string>()

    async function handleSubmit(event) {
        const form = event.currentTarget
        setErrorMessage(undefined)
        event.preventDefault()
        setValidated(true)
        if (form.checkValidity() === true && dateRangeValid && building) {
            const dateRange = new LondonDateRange(startDate, endDate)
            const submissionResult = await download(dateRange.range, building)
            submissionResult.fold(
                () => undefined,
                err => setErrorMessage(`An error occurred - #ask-tech for support. ${err}`)
            )
        } else {
            form.reportValidity()
        }
    }

    return <CardSection className={className} header={'UKPN Settlement Export'}>
        <Form noValidate validated={validated} >
            <BuildingInput testId={"UKPNBuildingInput"} className={'mb-3'} buildings={buildings} onBuildingChange={b => {
                setErrorMessage(undefined)
                setBuilding(b)
            }} />
            <DateRangePicker testId={'ukpnpicker'} start={startDate} end={endDate} onEndChange={setEndDate} onStartChange={setStartDate} setErrorMessage={setErrorMessage} reportIsValid={setDateRangeValid} />
            <DownloadCSVButton dataTestId='UKPNSettlementExport'
                disabled={!(startDate && endDate && (startDate <= endDate))}
                onSubmit={handleSubmit}/>
            {errorMessage &&
                <Alert data-testid='errorMessage' variant='danger'>{errorMessage}</Alert>}
        </Form>
    </CardSection>
}
