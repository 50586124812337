import {Badge, OverlayTrigger, Tooltip} from "react-bootstrap";
import React from "react";
import {EventStatus} from "../../model/EventStatus";

interface EventStatusBadgeProps {
    eventStatus: EventStatus
}

export const EventStatusBadge = ({eventStatus}: EventStatusBadgeProps) => {
    const badgeText = `${eventStatus.status} / ${eventStatus.logicalStatus ?? 'null'}`
    const explanation: string | undefined = eventStatus.explain()
    const badge =
        <Badge data-testid='statusBadge' bg='' style={{backgroundColor: eventStatus.toColour()}}>{badgeText}</Badge>
    if (explanation)
        return <OverlayTrigger
            placement='right' delay={{show: 250, hide: 400}}
            overlay={<Tooltip data-testid='eventStatusBadgeTooltip'>{explanation}</Tooltip>}>
            {badge}
        </OverlayTrigger>
    else
        return badge
}
