import {Button} from "@mui/material";
import React, {useState} from "react";
import {Result} from "../../client/Result";
import {AlertInfo, UserAlert} from "./UserAlert";
import {styled} from '@mui/material/styles';
import {UploadIcon} from "../icons/Icons";

interface UploadButtonProps {
    onFileUpload: (file: File) => Promise<Result<null>>
}

export const UploadButton: React.FC<UploadButtonProps> = ({onFileUpload}) => {
    const [showAlert, setShowAlert] = useState(false)
    const [alertInfo, setAlertInfo] = useState<AlertInfo>()

    const uploadFile = async (file: File) => {
        const result = await onFileUpload(file)
        const alertInfo: AlertInfo = result.fold(
                () => ({message: 'File uploaded successfully', type: 'success'}),
                message => ({message: message, type: 'error'})
        )
        setAlertInfo(alertInfo)
        setShowAlert(true)
    }

    const Input = styled('input')({})

    return <>
            <Button variant="contained" component="label" startIcon={<UploadIcon/>}>
                Upload File
                <Input hidden type="file" onChange={async (e) => {
                    if (e.target.files != null) {
                        await uploadFile(e.target.files[0])
                    }
                }}></Input>
            </Button>
        <UserAlert show={showAlert} setShow={setShowAlert} alertInfo={alertInfo}/>
    </>
}
