import * as React from "react";
import {Card} from "react-bootstrap";

export interface CardProps{
    header: string,
    className?: string,
    children,
    'data-testid'?: string
}

export const CardSection = ({header, className, children, 'data-testid': dataTestId} : CardProps) =>
    <Card className={className} data-testid={dataTestId} >
        <Card.Header as="h5" data-testid={'card-header'}>{header}</Card.Header>
        <Card.Body>
            {children}
        </Card.Body>
    </Card>