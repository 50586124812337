import {Spinner} from "react-bootstrap";
import {useState} from "react";
import {CardSection} from "./common/Card";
import {BuildingWithDeviceIds} from "../client/BuildingClient";
import {Device, DeviceClient} from "../client/DeviceClient";
import Alert from "react-bootstrap/Alert";
import {useNavigate} from "react-router-dom";
import {deviceInputsToMpansMappingWithDeviceSelected} from "../Routes";
import {SelectField} from "./common/SelectField";
import {SubmitButton} from "./common/SubmitButton";


export interface LinkingProps {
    devices: Device[]
    buildings: BuildingWithDeviceIds[]
    linkDeviceToBuilding: (string, number) => Promise<boolean>
    deviceClient: DeviceClient
}

interface LinkableDevice {
    id: string
    name: string
}
interface UnlinkedBuilding {
    id: number
    name: string
}

export const LinkBuildingToDevice = ({devices, buildings, linkDeviceToBuilding, deviceClient}: LinkingProps) => {
    const [deviceId, setDeviceId] = useState<string>()
    const [buildingId, setBuildingId] = useState<number>()
    const [loading, setLoading] = useState<boolean>(false)
    const [linkingError, setLinkingError] = useState<string>()
    const [linkedMessage, setLinkedMessage] = useState<string>()
    const navigate = useNavigate()

    const devicesLinkedToFake: LinkableDevice[] = devices
        .flatMap((d) => d.fake ? [{id: d.deviceId, name: d.deviceId}] : [])

    const unlinkedBuildings: UnlinkedBuilding[] = buildings
        .flatMap((s) => (s.deviceIds.length == 0) ? [{id: s.id, name: s.name}] : [])

    async function handleLinkButton() {
        if (deviceId && buildingId) {
            setLoading(true)
            const linkingSucceeded = await linkDeviceToBuilding(deviceId, buildingId)
            if(!linkingSucceeded){
                setLinkingError(`Failed to link device ${deviceId} to building ${buildingId}`)
            } else {
                const buildingName = buildings.find(s => s.id === buildingId)?.name;
                setLinkedMessage(`Device ${deviceId} has been linked to building ${buildingName}`)
                const deviceDetails = await deviceClient.getDeviceDetails(deviceId)
                if (deviceDetails == null) {
                    setLinkingError(`Failed to retrieve the details for the linked device ${deviceId}`)
                } else {
                    navigate(deviceInputsToMpansMappingWithDeviceSelected.withParams(deviceId))
                }
            }
            setLoading(false)
        }
    }

    function spinner() {
        if (loading) {
            return <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true'/>
        } else
            return <></>
    }

    function linkingButton() {
        const disabled = (!deviceId || !buildingId || loading || linkedMessage) ? true : false
        return <SubmitButton disabled={disabled}
                       onSubmit={handleLinkButton}>{spinner()}Link</SubmitButton>
    }


    const cardContent = <>
        <SelectField displayName={'Device'} name={'deviceId'} testId={`deviceId`} value={deviceId}
                     handleChange={(e) => setDeviceId(e.target.value)}
                     options={devicesLinkedToFake}/>
        <SelectField displayName={'Building'} name={'buildingId'} testId={`linking-buildingId`} value={buildingId}
                     handleChange={(e) => setBuildingId(Number(e.target.value))}
                     options={unlinkedBuildings}/>
        {linkingButton()}
        {linkedMessage? <Alert className="linking-confirmation" variant="info" key="linking-confirmation"
                          data-testid="linking-confirmation">{linkedMessage}</Alert> : <></>}
        {linkingError ? <Alert className="linking-alert" variant="warning" key="linking-alert"
                               data-testid="linking-alert">{linkingError}</Alert> : <></>}
    </>
    return <CardSection header={'Link device to building'}>
        {cardContent}
    </CardSection>
}
