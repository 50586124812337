import {useEffect, useState } from 'react'
import { useAuthContext } from './AuthContext' 
import {Entitlement, Loading} from './Entitlement';


export function useEntitlements(): Entitlement {
    const [entitlement, setEntitlement] = useState<Entitlement>(new Loading())
    const authContext = useAuthContext()

    useEffect(() => {
        if (authContext) {
            authContext.userInfo().then(bs => {
                setEntitlement(bs.entitlement)
            })
        }
    }, [authContext])

    return entitlement
}
