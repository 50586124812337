import {DateRange} from "./client/DataClient"
import {DateTime} from "./time/DateTime";

export class LondonDateRange {
    range: DateRange
    constructor(start: string, end: string){
         this.range = new DateRange(
             DateTime.fromISODateString(start).toLondonTimeZone(),
             DateTime.fromISODateString(end).toLondonTimeZone().plus({days : 1}),
         )
    }
}

export class ISODateRange {
    public readonly start: string
    public readonly end: string

    constructor(start: string, end: string) {
        this.start = DateTime.fromISODateString(start).toISODate()
        this.end = DateTime.fromISODateString(end).toISODate()
    }
}
