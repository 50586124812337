import {BuildingClient} from "../../client/BuildingClient";
import {DataClient, DateRange} from "../../client/DataClient";
import {useEffect, useState} from "react";
import {Building} from "../BuildingStatus";
import fileDownload from "js-file-download";
import {ISODateRange, LondonDateRange} from "../../ISODateRange";
import {MeterData} from "../MeterData";
import {BillingExport} from "../BillingExport";
import {baseLineMethodology, BillingExplanation} from "../BillingExplanation";
import {Result} from "../../client/Result";
import {UKPNSettlementExport} from "../UKPNSettlementExport";
import {LoadingIndicator} from "../common/LoadingIndicatior";
import {UploadHalfHourlyConsumption} from "../common/UploadHalfHourlyConsumption";
import {useEntitlements} from "../../EntitlementProvider";
import {Permission} from "../../Permission";
import {MeterApi, MeterClient} from "../../client/MeterClient";

export interface DataPageProps {
    buildingClient: BuildingClient
    meterClient: MeterClient
    dataClient: DataClient
}
export const DataPage = ({ buildingClient, meterClient, dataClient }: DataPageProps) => {
    const [buildings, setBuildings] = useState<{id: number, name: string}[]>()
    const [meters, setMeters] = useState<MeterApi[]>()
    const entitlement = useEntitlements()

    function download(filename: string, result: Result<string>): Result<void> {
        return result.map(value => {
            fileDownload(value, filename)
        })
    }

    async function downloadHalfHourlyData(mpan: string, start: string, end: string): Promise<Result<void>> {
        const result = await dataClient.retrieveMeterHalfHourlyConsumptionForMpan(mpan, new LondonDateRange(start, end))
        return download(`${mpan}_${start}_${end}_hh.csv`, result)

    }
    async function downloadFifteenSecondData(mpan: string, start: string, end: string): Promise<Result<void>> {
        const result = await dataClient.retrieveMeterFifteenSecondData(mpan, new LondonDateRange(start, end))
        return download(`${mpan}_${start}_${end}_fifteen.csv`, result)
    }

    async function downloadBillingExport(range: ISODateRange): Promise<Result<void>> {
        const result = await dataClient.retrieveBillingExport(range)
        return download(`billing_exports_${range.start}_${range.end}.csv`, result)
    }

    async function downloadUKPNSettlement(range: DateRange, building: Building): Promise<Result<void>> {
        const result = await dataClient.readingsUKPNSettlement(building.id, range)
        return download(`UKPN_settlement_${range.start.toISODate()}_${range.end.toISODate()}.csv`, result)
    }

    async function downloadBillingExplanation(buildingId: number, range: ISODateRange, methodology: baseLineMethodology): Promise<Result<void>> {
        const result = await dataClient.retrieveBillingExplanation(buildingId, range, methodology)
        return download(`explain_billing_${methodology}_${buildingId}_${range.start}_${range.end}.csv`, result)
    }

    useEffect(() => {
        buildingClient.retrieveBuildingsWithMeterMpans().then(bs => setBuildings(bs.map(b => ({ id: b.id, name: b.name }))))
    }, [buildingClient])

    useEffect(() => {
        meterClient.retrieveMeters().then(ms => setMeters(ms))
    }, [meterClient])

    return meters && buildings ? <div>
        <div className={'row my-md-3'}>
            <MeterData className={'col-md px-0 mx-sm-3 '} meters={meters.filter(m => m.readingsGranularities.includes('PT30M'))}
                download={downloadHalfHourlyData} header={'Half Hour Consumption Data'} />
            <MeterData className={'col-md px-0 mx-sm-3 '} meters={meters.filter(m => m.readingsGranularities.includes('PT15S'))}
                download={downloadFifteenSecondData} header={'15 Second Meter Data'} />
        </div>
        <div className={'row my-md-3'}>
            <BillingExport className={'col-md px-0 mx-sm-3 '}
                download={downloadBillingExport} />
            <BillingExplanation
                className={'col-md px-0 mx-sm-3 '}
                buildingsSource={buildings}
                downloadBaseline={downloadBillingExplanation}
                header={'Explain Billing'} />
        </div>
        <div className={'row my-md-3'}>
            <UKPNSettlementExport className={'col-md px-0 mx-sm-3 '}
                download={downloadUKPNSettlement}
                buildings={buildings}
            />
        {entitlement.has(Permission.WRITE_READINGS) ?
            <UploadHalfHourlyConsumption className={'col-md px-0 mx-sm-3 '} onFileUpload={file => dataClient.uploadReadings(file)} />
            : null
        }
        </div>
    </div> : <LoadingIndicator></LoadingIndicator>
}