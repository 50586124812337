import {appRoutes} from "../../Routes";
import {SideBar} from "./SideBar";
import { useEntitlements } from "../../EntitlementProvider";

export interface PageProps {
    className?: string,
    children?
}

export const Page = ({children, className}: PageProps) => {
    const entitlement = useEntitlements()

    return <div className={'app'}>
        <div data-testid={'sideBarContainer'} className={'sideBarContainer'}>
            <SideBar routes={appRoutes.filter(route => entitlement.can(route))}/>
        </div>
        <div className={`container ${className}`}>
            {children}
        </div>
    </div>
}