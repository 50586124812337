import {CardSection} from "./common/Card";
import {SelectField} from "./common/SelectField";
import {useEffect, useState} from "react";
import Alert from "react-bootstrap/Alert";
import {buildingStatus} from "../Routes";
import {useNavigate} from "react-router-dom";
import {Result} from "../client/Result";
import {DeviceRegistration} from "../client/DeviceClient";
import {SubmitButton} from "./common/SubmitButton";


export interface DeviceInputsToMetersLinkingCardProps {
    onSubmit: (deviceId: string, mappings: DeviceToMeterMapping[]) => Promise<boolean>
    deviceId?: string | undefined
    devicesSource: () => Promise<Result<string[]>>
    deviceDetailsSource: (deviceId: string) => Promise<DeviceRegistration | null>
}

export interface DeviceToMeterMapping {
    mpan: Mpan
    deviceInput: DeviceInput
}

export interface Mpan {
    id: string
}

export interface DeviceInput {
    imageMeterId: string
}

interface MeterToDeviceMappingProps {
    mpan: string,
    deviceInputs:  { name: string; id: string }[],
    testId: string,
    onChange: (mpan, deviceInputId) => void
}

export const MeterToDeviceMapping = ({mpan, deviceInputs, testId, onChange}: MeterToDeviceMappingProps) => {
    const [deviceInputId, setDeviceInputId] = useState<string | undefined >(undefined)

    const handleChange = (e)=>{
        const deviceInputId: string = e.target.value

        setDeviceInputId(deviceInputId)
        onChange(mpan, deviceInputId)
    };

    return <div>
        {mpan} : <SelectField options={deviceInputs} value={deviceInputId} displayName={'Device input'} name={'bob'} testId={testId} handleChange={handleChange}/>
            </div>
}


interface DeviceSelectorProps {
    deviceId: string | undefined
    deviceIds: string[]
    onSelection: (deviceId: string) => any
    disabled: boolean
}

const DeviceSelector = ({deviceId, deviceIds, onSelection, disabled}: DeviceSelectorProps) => {

    async function handleChange(e: any) {
        await onSelection(e.target.value)
    }

    const options: { id: string, name: string }[] = deviceIds.map(id => ({id: id, name: id}))

    return <>
        <SelectField displayName={'Device'} value={deviceId} handleChange={handleChange} name={'Building'} options={options}
                     testId={'select-device'} disabled={disabled} />
    </>;
}

export const DeviceInputsToMetersLinkingCard = ({onSubmit, deviceId, devicesSource, deviceDetailsSource}: DeviceInputsToMetersLinkingCardProps) => {

    const [internalDeviceId, setInternalDeviceId] = useState<string|undefined>(deviceId)
    const [allowDeviceSelection, setAllowDeviceSelection] = useState<boolean>(false)
    const [devices, setDevices] = useState<string[]>([])
    const [mappings, setMappings] = useState<Map<string, string>>(new Map())
    const [errorMessage, setErrorMessage] = useState<string>()
    const navigate = useNavigate()

    const [mpans, setMpans] = useState<Mpan[]>([])

    const [deviceInputs, setDeviceInputs] = useState<{ name: string, id: string }[]>([])

    const [buildingId, setBuildingId] = useState<number>()

    useEffect(() => {
        if(internalDeviceId) {
            deviceDetailsSource(internalDeviceId).then(details => {
                if (details != null) {
                    const inputIds = details.defaultImageMeterIds
                    const inputs: { name: string; id: string }[] = inputIds.map((id, index) => {
                        return {name: `comsport ${index + 1} (${id})`, id: id}
                    });
                    setDeviceInputs(inputs)
                    details.buildingId && setBuildingId(details.buildingId)
                    setMpans((details.meterMpans == undefined) ? [] : details.meterMpans.map(m => ({id: m})))
                }
            })
        } else {
            devicesSource().then(
                result => result.fold(devices =>{
                    setDevices(devices);
                    setAllowDeviceSelection(true)
                },t => {throw Error(t)})
            )
        }
    }, [internalDeviceId])

    function handleDeviceChange(deviceId: string){
        setInternalDeviceId(deviceId)
        setAllowDeviceSelection(false)
    }

    const onChange = (mpan, imageMeterId) =>{
        mappings.set(mpan, imageMeterId);
        setMappings(new Map(mappings))
    }

    const handleSubmission: () => Promise<void> = async () => {
        const result = await onSubmit(internalDeviceId!, Array.from(mappings.entries()).map((entry) => {
            return {mpan: {id: entry[0]}, deviceInput: {imageMeterId: entry[1]}};
        }));

        if (!result) {
            setErrorMessage('Failed to link device input(s) to meter(s)')
        } else {
            navigate(buildingStatus.withParams(buildingId!))
        }
    }

    function hasRepetitions(items: string[]): boolean {
        return items.length !== (new Set<string>(items)).size
    }

    function submitButtonShouldBeDisabled() {
        return !internalDeviceId || !buildingId || mappings.size === 0 || hasRepetitions(Array.from(mappings.keys())) || hasRepetitions(Array.from(mappings.values()))
    }

    const content = mpans.map((mpan, index)=>{
        return <MeterToDeviceMapping key={`meterToDeviceMapping-${index}`} deviceInputs={deviceInputs} mpan={mpan.id} onChange={onChange} testId={`meterToDeviceMapping-${index}`}/>
    })

    function shouldAllowDeviceSelection(): boolean {
        return allowDeviceSelection
    }

    return <CardSection header={'Link device inputs to MPANs'}>
        {!deviceId?<DeviceSelector deviceId={internalDeviceId} deviceIds={devices} onSelection={handleDeviceChange}
                         disabled={!shouldAllowDeviceSelection()}/>:<></>}
        {content}
        <SubmitButton onSubmit={handleSubmission} disabled={submitButtonShouldBeDisabled()} dataTestId='linkDeviceToMeterSubmitButton'>Submit</SubmitButton>
        {errorMessage ? <Alert className="link-device-to-meter-alert" variant="danger" key="link-device-to-meter-alert"
                               data-testid="link-device-to-meter-alert">{errorMessage}</Alert> : <></>}

    </CardSection>
}
