import {useState} from 'react';
import {Spinner} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import {TestScheduleError} from "../client/EventScheduleClient";
import './BuildingStatus.scss';
import {BuildingInput} from "./BuildingInput";
import {ArrowUpFromSquare} from "./icons/Icons";
import {AsyncComponent} from "./common/AsyncComponent";
import {calendar} from "../Routes";
import { Branded } from '../utils/BrandedTypes';
import {SubmitButton} from "./common/SubmitButton";


export type Mpan = Branded<string, "Mpan">

export interface Building {
    name: string,
    id: number,
}

export interface BuildingWithMpans {
    id: number,
    name: string,
    mpans: Mpan[],
}

export interface LatestMeterReadings {
    value: number
    lastReading: string
    mpan: string
    serialNumber: string
}

export interface MonitorData {
    lastHeartbeats: { deviceId: string, messageTimeIn: string }[]
    latestMeterReadings: LatestMeterReadings[]
}


interface BuildingSelectorStatusProps {
    buildingsSource: Building[]
    retrieveMonitorData: (buildingId: number) => Promise<MonitorData>
    scheduleEvent: (buildingId: number) => Promise<TestScheduleError | null>,
}

interface AsyncBuildingSelectorStatusProps {
    buildingsSource: () => Promise<Building[]>
    retrieveMonitorData: (buildingId: number) => Promise<MonitorData>
    scheduleEvent: (buildingId: number) => Promise<TestScheduleError | null>,
}

export const AsyncBuildingSelector = ({buildingsSource, retrieveMonitorData, scheduleEvent}: AsyncBuildingSelectorStatusProps)=>{

    return <AsyncComponent loaderFunction={buildingsSource} dataPropName={'buildingsSource'}>
        <BuildingStatus buildingsSource={[]} retrieveMonitorData={retrieveMonitorData} scheduleEvent={scheduleEvent}/>
    </AsyncComponent>
}

export const BuildingStatus = ({buildingsSource, retrieveMonitorData, scheduleEvent}: BuildingSelectorStatusProps) => {
    const [selectedBuilding, setSelectedBuilding] = useState<Building>()
    const [monitorData, setMonitorData] = useState<MonitorData>()

    function handleChange(b: Building) {
        setSelectedBuilding(b)
        setMonitorData(undefined)
        retrieveMonitorData(b.id).then((response) =>
            setMonitorData(response)
        )
    }

    return <>
        <BuildingInput className={'mb-3'} buildings={buildingsSource} onBuildingChange={handleChange}/>
        {selectedBuilding && monitorData && <BuildingData data={monitorData} scheduleEvent={()=> scheduleEvent(selectedBuilding?.id)}/>}
    </>
}

interface BuildingDataProps {
    data: MonitorData
    scheduleEvent: () => Promise<TestScheduleError | null>
}

const TestScheduleOutcome = ({errorMessages}: { errorMessages?: string[] }) => {
    let alert: JSX.Element[] | JSX.Element
    if (errorMessages) {
        alert = errorMessages.map((m, i) => {
            const id = `error-schedule-event-message-${i}`
            return <Alert className={'buildingStatus-alert'} variant='warning' key={id} data-testid={id}>{m}</Alert>
        })
    } else {
        alert = <Alert variant='success' data-testid='test-schedule-success-alert' className={'buildingStatus-alert'}>
            <div className={'buildingStatus-alert-message'}>
                <span>Event created. View on calendar</span>
                <a href={calendar.path} target="_blank" rel="noreferrer" data-testid="go-to-event-calendar-control"><ArrowUpFromSquare/></a>
            </div>
        </Alert>
    }

    return <div data-testid='test-schedule-outcome'>{alert}</div>
}

export const BuildingData = ({data, scheduleEvent}: BuildingDataProps) => {
    const [showTestScheduleOutcomeMessages, setShowTestScheduleOutcomeMessages] = useState<boolean>(false)
    const [isSubmittingTestSchedule, setIsSubmittingTestSchedule] = useState<boolean>(false)
    const [scheduleEventErrorMessage, setScheduleEventErrorMessage] = useState<TestScheduleError | null>(null)

    async function handleSubmit() {
        setIsSubmittingTestSchedule(true)
        setScheduleEventErrorMessage(await scheduleEvent())
        setIsSubmittingTestSchedule(false)
        setShowTestScheduleOutcomeMessages(true)
    }

    function spinner() {
        if (isSubmittingTestSchedule) {
            return <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true'/>
        } else
            return <></>
    }

    const heartbeat = <div data-testid='lastHeartbeats'>{
        (data.lastHeartbeats.length > 0) ? <>Last heartbeats:
                <ul>{data.lastHeartbeats.map((h) =>
                    <li data-testid={`lastHeartbeat-${h.deviceId}`}
                        key={h.deviceId}>{`Device id '${h.deviceId}' last seen at ${h.messageTimeIn}`}</li>)}</ul>
            </> :
            'No device ever seen for the building'
    }</div>

    const readings = <div data-testid='lastReading'>
        {(data.latestMeterReadings.length > 0) ? <>Latest meter readings: <ul>
            {data.latestMeterReadings.map(r => <li
                data-testid={'li-' + r.mpan} key={r.mpan}>mpan: {r.mpan} had value {r.value} at {r.lastReading}</li>
            )}
        </ul></> : <>No meter readings received for this device</>
        }
    </div>
    return <>
        {heartbeat}
        {readings}
        <Form.Group>
            <SubmitButton dataTestId={'schedule-event'} onSubmit={handleSubmit} disabled={isSubmittingTestSchedule}>
                {spinner()}
                10 minute Dispatch
            </SubmitButton>

            {showTestScheduleOutcomeMessages &&
                <TestScheduleOutcome errorMessages={scheduleEventErrorMessage?.messages}/>}
        </Form.Group>
    </>
}
