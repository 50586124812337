import {BaseClient, DataType} from "./BaseClient";
import { ISODateRange, LondonDateRange } from "../ISODateRange";
import { DateTime } from "../time/DateTime";
import { baseLineMethodology } from "../components/BillingExplanation";
import { Result } from "./Result";

export class DateRange {
    public readonly start: DateTime
    public readonly end: DateTime

    constructor(start: DateTime, end: DateTime) {
        this.start = start
        this.end = end
    }

    public contains(dateTime: DateTime): boolean {
        return dateTime.toMillis() > this.start.toMillis() && dateTime.toMillis() < this.end.toMillis()
    }
}

export interface ApiPowerEntry {
    time: string
    powerInKilowatts: number
}

export interface PowerEntry {
    date: number,
    power: number
}

export function apiPowerEntryToPowerEntry(apiReading: ApiPowerEntry): PowerEntry {
    return {date: DateTime.fromISO(apiReading.time).toUnixInteger(), power: apiReading.powerInKilowatts / 1000.0}
}

export class DataClient {

    baseClient: BaseClient

    constructor(baseClient: BaseClient) {
        this.baseClient = baseClient
    }

    async powers(buildingId: number, range: DateRange): Promise<PowerEntry[]> {
        const url = `/tooling/api/power` +
                `?buildingId=${buildingId}` +
                `&startTime=${range.start.toUTC().toISO()}` +
                `&endTime=${range.end.toUTC().toISO()}` +
                `&granularity=PT5M`
        const apiReadings = await this.baseClient.getOk<ApiPowerEntry[]>(url)
        return apiReadings.map(apiPowerEntryToPowerEntry)
    }

    async readingsUKPNSettlement(buildingId: number, range: DateRange): Promise<Result<string>> {
        const url = `/tooling/api/readings` +
                `?buildingId=${buildingId}` +
                `&startTime=${range.start.toUTC().toISO()}` +
                `&endTime=${range.end.toUTC().toISO()}` +
                `&variant=ukpnSettlement`
        return await this.baseClient.getOkCsv(url)
    }

    async uploadReadings(file: File): Promise<Result<null>> {
        const url = `/tooling/api/hhConsumption`
        return await this.baseClient.postOk(url, file, DataType.FORM_DATA)
    }

    async retrieveMeterHalfHourlyConsumptionForMpan(mpan: string, londonRange: LondonDateRange): Promise<Result<string>> {
        const api = `/tooling/api/meterreadings/download/${mpan}?startTime=${londonRange.range.start.toUTC().toISO()}&endTime=${londonRange.range.end.toUTC().toISO()}&format=hh`
        return await this.baseClient.getOkCsv(api);
    }

    async retrieveMeterFifteenSecondData(mpan: string, londonRange: LondonDateRange): Promise<Result<string>> {
        const api = `/tooling/api/meterreadings/download/${mpan}?startTime=${londonRange.range.start.toUTC().toISO()}&endTime=${londonRange.range.end.toUTC().toISO()}`
        return await this.baseClient.getOkCsv(api);
    }

    async retrieveBillingExport(range: ISODateRange): Promise<Result<string>> {
        const api = `/tooling/api/billing/invoice/start/${range.start}/end/${range.end}`
        return await this.baseClient.getOkCsv(api);
    }

    async retrieveBillingExplanation(buildingId: number, range: ISODateRange, methodology: baseLineMethodology): Promise<Result<string>> {
        return (methodology == 'OldPreviousHhAverage')
            ? this.retrieveBillingExplanationHalfHour(buildingId, range)
            : this.retrieveBillingExplanationHistoricBaseline(buildingId, new LondonDateRange(range.start, range.end), methodology)
    }

    private async retrieveBillingExplanationHalfHour(buildingId: number, range: ISODateRange): Promise<Result<string>> {
        const api = `/tooling/api/billing/events/start/${range.start}/end/${range.end}/building/${buildingId}`
        return await this.baseClient.getOkCsv(api);
    }

    private async retrieveBillingExplanationHistoricBaseline(buildingId: number, londonRange: LondonDateRange, methodology: baseLineMethodology): Promise<Result<string>> {
        const api = `/tooling/api/billing/baseline?buildingId=${buildingId}&from=${londonRange.range.start.toUTC().toISO()}&to=${londonRange.range.end.toUTC().toISO()}&methodology=${methodology}`
        return await this.baseClient.getOkCsv(api);
    }

}
