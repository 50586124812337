import { EventSchedule, Schedule } from "../components/EventSchedule";
import { BaseClient } from "./BaseClient";
import { DateTime } from "../time/DateTime";
import { EventStatus } from "../model/EventStatus";
import { Failure, Result, Success } from "./Result";
import {parseResponseApiErrors, safeJson} from "./ApiErrorsParser";

export interface NewEvent {
    type: string
    startTime: DateTime
    endTime: DateTime
    buildingId: number
    title: string
}

export type UpdateEvent = NewEvent & WithEventId

export interface WithEventId {
    eventId: string
}

export type EitherEvent = Event | EventWithDetails

export const isEventDetails = (event: EitherEvent) : event is EventWithDetails  => (event as EventWithDetails).eventStatus !== undefined

export interface Event {
    id: string
    start: DateTime
    end: DateTime
    buildingId: number
    title: string
}

export interface EventWithDetails {
    id: string
    start: DateTime
    end: DateTime
    eventType: string
    eventStatus: EventStatus
    title: string
    buildingId: number
}

type ApiUnavailability = {
    id: string,
    start: string,
    end: string,
    buildingId: number,
    buildingName: string
}

type ApiNewUnavailability = Pick<ApiUnavailability, 'start' | 'end' | 'buildingId'>

export type Unavailability = EventWithDetails
export type ExistingUnavailability = Pick<Unavailability, 'id' | 'start' | 'end'>

export type NewUnavailability = Pick<Unavailability, 'start' | 'end' | 'buildingId'>

export type SiteEvent = Unavailability | EventWithDetails

interface ApiUpdateEvent {
    type: string,
    startTime: string,
    endTime: string,
    title: string,
    savings: number
}

export interface ApiValidatableEvent {
    id?: number | string,
    start: string,
    end: string,
    buildingIds: number[],
    type: string,
}

export interface ApiCreationValidatableEvent {
    start: string,
    end: string,
    buildingIds: number[],
    type: string,
}

export interface Interval {
    start: DateTime
    end: DateTime
}

export interface Constraint {
    dailyEvents: Interval[]
    maxEvents: number
    maxEventDuration: number
    recoveryTime: number
    totalDailyRunTime: number
}

interface ApiEvent {
    id: number | string
    start: string
    end: string
    buildingId: number
    eventType?: string
    status?: string
    logicalStatus?: string
    title: string
}

export interface TestScheduleError {
    messages: string[]
}

interface OplServiceError {
    message: string
}

interface ApiInterval {
    start: string
    end: string
}

export interface EventPerformance {
    eventId: number
    shouldThePerformanceWorryAPerson: boolean
}

interface ApiControlStrategy {
    maxTotalDurationMinutes: number,
    maxRunDuration: number,
    maxNumEvents: number,
    saving: number,
    recoveryDuration: number,
    rampUpDownTime: number,
}

interface ApiEventPerBuildingValidationInfo {
    controlStrategy: ApiControlStrategy
    otherEventsOnTheDay: ApiInterval[]
}


interface ApiEventValidationInfo {
    eventTypes: string[]
    eventPerBuildingValidationInfos: ApiEventPerBuildingValidationInfo[]
    existingEventWindow?: ApiInterval
}

export class EventScheduleClient {
    private baseClient: BaseClient;

    constructor(baseClient: BaseClient) {
        this.baseClient = baseClient;
    }

    public getAllEventSchedules(): Promise<EventSchedule[]> {
        return this.baseClient.getOk('/tooling/api/event/schedule')
    }

    public scheduleEvents(events: Schedule[]): Promise<Result<null>> {
        return this.baseClient.postOk("/tooling/api/event/schedule/batch", events)
    }

    public async getEventTypes(): Promise<string[]> {
        return (await this.baseClient.getOk<string[]>('/tooling/api/event/getType'))
    }

    public async getEventPerformance(start: DateTime, end: DateTime): Promise<Result<EventPerformance[]>> {
        return (await this.baseClient.getOkP<EventPerformance[]>(`/tooling/api/event/eventPerformance/start/${start.toISODate()}/end/${end.toISODate()}`))
    }

    private async parseCreateOrUpdateResponse(response: Response): Promise<{ messages: string[] } | null> {
        if (response.ok) return null
        else {
            const errorMessage: OplServiceError | string = await safeJson<OplServiceError>(response)
            if (typeof errorMessage === 'string') {
                return { messages: [`Failure response ${response.status}: ${errorMessage}`] }
            } else {
                const possiblyJsonMarshalledMessages = errorMessage.message
                try {
                    const messages: string[] = JSON.parse(possiblyJsonMarshalledMessages)
                    return { messages }
                } catch (_) {
                    return { messages: [possiblyJsonMarshalledMessages] }
                }
            }
        }
    }

    public async createEvent(event: NewEvent): Promise<Result<null>> {
        const apiEvent: ApiCreationValidatableEvent = {
            start: event.startTime.toISO(),
            end: event.endTime.toISO(),
            buildingIds: [event.buildingId],
            type: event.type,
        }

        const response = await this.baseClient.post('/tooling/api/event', apiEvent)
        const result = await this.parseCreateOrUpdateResponse(response);
        return (result == null) ? new Success(null) : new Failure(result.messages.join(","))
    }

    async scheduleTestEvent(buildingId: number): Promise<TestScheduleError | null> {
        const queryParams = new URLSearchParams({ testForBuildingId: buildingId.toString() })
        const response = await this.baseClient.post('/tooling/api/event?' + queryParams.toString())
        return await this.parseCreateOrUpdateResponse(response)
    }

    public async getEvents(start: DateTime, end: DateTime, buildingIds?: number[]): Promise<EitherEvent[]> {
        const rangeParams: { start: string; end: string } = { start: start.toUTC().toISO(), end: end.toUTC().toISO() }
        const queryParams = new URLSearchParams(rangeParams)
        if (buildingIds) {
            buildingIds.forEach(bid => {
                queryParams.append('buildingId', bid.toString())
            })
        }
        const response: ApiEvent[] = (await this.baseClient.getOk<ApiEvent[]>('/tooling/api/event?' + queryParams.toString()))
        return response.map(({
            id,
            start,
            end,
            eventType,
            status,
            logicalStatus,
            title,
            buildingId
        }: ApiEvent) => {
            const newId = id.toString()
            const startDateTime = DateTime.fromISO(start)
            const endDateTime = DateTime.fromISO(end)

            const details = eventType && status ? {
                eventType,
                eventStatus: new EventStatus(status, logicalStatus),
            } : {}

            return {
                id: newId,
                start: startDateTime,
                end: endDateTime,
                buildingId,
                title,
                ...details
            }
        })
    }

    public async updateUnavailability(event: ExistingUnavailability): Promise<Result<null>> {
        const updatedTimes = { start: event.start.toISO(), end: event.end.toISO() }
        return await this.baseClient.patchOk(`/tooling/api/unavailability/${event.id}`, updatedTimes)
    }

    public async createUnavailability(event: NewUnavailability): Promise<Result<null>> {
        const api: ApiNewUnavailability = {
            buildingId: event.buildingId,
            start: event.start.toISO(),
            end: event.end.toISO()
        }
        return await this.baseClient.postOk('/tooling/api/unavailability', api)
    }

    public async deleteUnavailability(unavailabilityId: string): Promise<Result<null>> {

        return await this.baseClient.deleteOk(`/tooling/api/unavailability/${unavailabilityId}`)
    }

    public async getUnavailability(start: DateTime, end: DateTime, buildingIds?: number[]): Promise<Unavailability[]> {
        const rangeParams: { start: string; end: string } = { start: start.toUTC().toISO(), end: end.toUTC().toISO() }
        const queryParams = new URLSearchParams(rangeParams)
        buildingIds?.forEach(bid => {
            queryParams.append('buildingId', bid.toString())
        })

        return (await this.baseClient.getOk<ApiUnavailability[]>('/tooling/api/unavailability?' + queryParams.toString()))
            .map(a => {
                if (a.id == undefined || a.end == undefined || a.start == undefined || a.buildingId == undefined || a.buildingName == undefined) throw Error('Value from api did not meet contract')
                return a
            })
            .map((a: ApiUnavailability) => ({
                eventStatus: new EventStatus('unavailability', undefined),
                eventType: 'Unavailable',
                title: a.buildingName,
                id: a.id,
                buildingId: a.buildingId,
                start: DateTime.fromISO(a.start),
                end: DateTime.fromISO(a.end)
            }))
    }

    public async updateEvent(event: UpdateEvent): Promise<Result<null>> {
        const apiEvent: ApiUpdateEvent = ({
            endTime: event.endTime.toISO(),
            savings: 0,
            startTime: event.startTime.toISO(),
            title: event.title,
            type: event.type
        })
        const response = await this.baseClient.patch(`/tooling/api/event/opl/${event.eventId}/update`, apiEvent)
        const parsedErrorResponse = await this.parseCreateOrUpdateResponse(response)
        return (parsedErrorResponse == null) ? new Success(null) : new Failure(parsedErrorResponse.messages.join(","))
    }

    public async cancelEvent(event: WithEventId): Promise<Result<null>> {
        const response = await this.baseClient.patch(`/tooling/api/event/stop`, [event.eventId])
        const parsedErrors = await parseResponseApiErrors(response)
        return (parsedErrors == null) ? new Success(null) : new Failure(parsedErrors.map(e => e.message).join(", "))
    }

    public async retrieveValidationInfo(event: NewEvent | UpdateEvent): Promise<Constraint> {
        const eventId = event['eventId']
        const eventAsInt = parseInt(eventId)
        const id = Number.isNaN(eventAsInt) ? eventId : eventAsInt
        const apiEvent: ApiValidatableEvent = ({
            id,
            start: event.startTime.toISO(),
            end: event.endTime.toISO(),
            buildingIds: [event.buildingId],
            type: event.type
        })
        const response = await this.baseClient.post(`/tooling/api/event?onlyRetrieveValidationInformation`, apiEvent)
        if (response.ok) {
            const validationInfo: ApiEventValidationInfo = await response.json()
            const controlStrategy = validationInfo.eventPerBuildingValidationInfos[0].controlStrategy;
            const dailyEvents = validationInfo.eventPerBuildingValidationInfos[0].otherEventsOnTheDay
                .map(i => ({
                    start: DateTime.fromISO(i.start),
                    end: DateTime.fromISO(i.end)
                }))
            return {
                dailyEvents: dailyEvents,
                maxEventDuration: controlStrategy.maxRunDuration,
                maxEvents: controlStrategy.maxNumEvents,
                recoveryTime: controlStrategy.recoveryDuration,
                totalDailyRunTime: controlStrategy.maxTotalDurationMinutes
            }
        } else {
            throw new Error('Failed to retrieve event validation information')
        }
    }
}
