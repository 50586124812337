import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import enGbLocale from '@fullcalendar/core/locales/en-gb'
import { DateSelectArg, EventClickArg, EventContentArg, EventSourceFuncArg } from '@fullcalendar/core'
import {useEffect, useState, useRef} from 'react'
import {
    Constraint,
    Event,
    EventScheduleClient,
    EventWithDetails,
    SiteEvent,
    Unavailability,
    WithEventId,
    isEventDetails
} from '../../client/EventScheduleClient'
import { CalendarModalEvent, CalendarModalExistingEvent, CancelEventModal, CreateEventModal, EditEventModal } from './CalendarEventModals'
import { Building } from '../BuildingStatus'
import { DateTime } from '../../time/DateTime'
import { DateRange } from "../../client/DataClient";
import { useDateTimeContext } from "../../DateTimeContext";
import { EventStatus } from "../../model/EventStatus";
import { useInterval } from "../../customHooks/useInterval";
import { Result } from "../../client/Result";
import { useEntitlements } from '../../EntitlementProvider'
import { Permission } from '../../Permission'
import { NotificationClient } from '../../client/NotificationsClient'
import './calendar.scss'
import luxonPlugin from '@fullcalendar/luxon3'
import { CircleAlertIcon } from '../icons/Icons'
import { useDebounceCallback } from '../../customHooks/useDebounce'

export interface CalendarProps {
    buildings: Building[]
    selectedBuildings: Building[],
    eventClient: EventScheduleClient,
    notificationClient: NotificationClient,
    eventTypes: string[]
    refreshTime: number | null
    initialEvents?: CalendarEvent[]
}

interface CalendarEventExtendedProps {
    type: string
    buildingId: number
    eventId: string
    eventStatus: EventStatus
    shouldBeWorried: boolean
}

interface CalendarEvent {
    extendedProps: CalendarEventExtendedProps
    title: string
    start: string
    end: string
}

export const Calendar = ({ buildings, eventClient, notificationClient, eventTypes, selectedBuildings = [], refreshTime, initialEvents = [] }: CalendarProps) => {
    const calendarRef = useRef<FullCalendar>(null)
    const now: () => DateTime = useDateTimeContext()

    const entitlements = useEntitlements()

    const [events, setEvents] = useState<CalendarEvent[]>(initialEvents)

    const [showModal, setShowModal] = useState<boolean>(false)
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false)
    const [updateEvent, setUpdateEvent] = useState<CalendarModalExistingEvent>()
    const [newEventDates, setNewEventDates] = useState<DateRange>()
    const [calendarInfo, setCalendarInfo] = useState<EventSourceFuncArg>()
    const [successfulEvents, setSuccessfulEvents] = useState<Map<string, boolean>>(new Map())

    const allowModal = eventTypes.length > 0

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                const calendarApi = calendarRef.current?.getApi();
                if (calendarApi) {
                    cleanModalState()
                    calendarApi.unselect()
                }
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }

    }, [])
    async function handleUnderPerformingEvents(start: Date, end: Date) {
        const results = await eventClient.getEventPerformance(DateTime.fromJSDate(start), DateTime.fromJSDate(end).plus({days: 1}))

            results.fold(eventPerformance =>
                    setSuccessfulEvents(new Map(eventPerformance.map(f => [f.eventId.toString(), f.shouldThePerformanceWorryAPerson]))),
                () => undefined
            )

    }

    useEffect(() => {
        if (calendarInfo) {
            handleUnderPerformingEvents(calendarInfo.start, calendarInfo.end).then(() => {
                return
            })
        }
        refreshEvents().then(() => {
            return
        })
    }, [calendarInfo?.start, calendarInfo?.end, entitlements])

    useEffect(() => {
        refreshEvents().then(() => {
            return
        })
    }, [ selectedBuildings])


    useInterval(() => { refreshEvents() }, refreshTime)

    async function retrieveCalendarEvents(start: DateTime, end: DateTime): Promise<[EventWithDetails[], Event[]]> {
        const buildingIds = selectedBuildings.length == 0 ? undefined : selectedBuildings.map(b => b.id)
        const allEvents = await eventClient.getEvents(start, end, buildingIds)

        return [allEvents.filter(isEventDetails), allEvents.filter(e => !isEventDetails(e))]

    }

    async function retrieveUnavailabilityEvents(start: DateTime, end: DateTime, selectedBuildings: Building[]): Promise<Unavailability[]> {
        const buildingIds = selectedBuildings.length == 0 ? undefined : selectedBuildings.map(b => b.id)
        return await eventClient.getUnavailability(start, end, buildingIds)
    }


    async function refreshEvents(): Promise<void> {
        async function extracted(startDate: string, endDate: string) {
            const start = DateTime.fromISO(startDate);
            const end = DateTime.fromISO(endDate);
            const [refreshedEvents, hiddenEvents] =
                entitlements.has(Permission.READ_EVENT) || entitlements.has(Permission.READ_EVENT_DETAILS) ? await retrieveCalendarEvents(start, end) : [[], []]
            const unavailableEvents: Unavailability[] = entitlements.has(Permission.READ_UNAVAILABILITY) ? await retrieveUnavailabilityEvents(start, end, selectedBuildings) : []

            const extendedProps = (type: string, buildingId: number, eventId: string, eventStatus: EventStatus): CalendarEventExtendedProps => ({
                type,
                buildingId,
                eventId,
                eventStatus,
                shouldBeWorried: !!successfulEvents.get(eventId)
            })

            const transformHiddenEvents = hiddenEvents.map((e: Event) => ({
                extendedProps: extendedProps('Event', e.buildingId, e.id, new EventStatus('senttodevice')),
                id: e.id,
                start: e.start.toISO(),
                end: e.end.toISO(),
                title: e.title,
                color: 'DarkGreen',
            }))

            return unavailableEvents.concat(refreshedEvents).map((e: SiteEvent) => {
                return ({
                    extendedProps: extendedProps(e.eventType, e.buildingId, e.id, e.eventStatus),
                    id: e.id,
                    start: e.start.toISO(),
                    end: e.end.toISO(),
                    title: e.title,
                    color: e.eventStatus.toColour(),
                })
            }).concat(transformHiddenEvents)
        }

        if (calendarInfo) {
            successfulEvents
            const refreshedCalendarEvents = await extracted(calendarInfo.startStr, calendarInfo.endStr);
            setEvents(refreshedCalendarEvents)
        }
    }

    async function handleCalendarModalEventCreation(event: CalendarModalEvent): Promise<Result<null>> {
        let creationResult: Result<null>;
        if (event.type.toLowerCase() == 'unavailable') {
            creationResult = await eventClient.createUnavailability({
                start: event.startTime,
                end: event.endTime,
                buildingId: event.building.id,
            })
        } else {
            creationResult = await eventClient.createEvent({
                type: event.type,
                startTime: event.startTime,
                endTime: event.endTime,
                buildingId: event.building.id,
                title: event.building.name
            })
        }

        await refreshEvents()

        return creationResult
    }

    async function handleCalendarModalEventUpdate(event: CalendarModalEvent & WithEventId): Promise<Result<null>> {
        let result: Result<null>

        if (event.type.toLowerCase() == 'unavailable') {
            result = await eventClient.updateUnavailability({
                id: event.eventId,
                start: event.startTime,
                end: event.endTime
            })
        } else {
            result = await eventClient.updateEvent({
                eventId: event.eventId,
                type: event.type,
                startTime: event.startTime,
                endTime: event.endTime,
                buildingId: event.building.id,
                title: event.building.name
            })
        }

        await refreshEvents()

        return result
    }

    async function retrieveValidationInfo(event: CalendarModalEvent | (CalendarModalEvent & WithEventId)): Promise<Constraint> {
        return await eventClient.retrieveValidationInfo({
            eventId: event['eventId'],
            type: event.type,
            startTime: event.startTime,
            endTime: event.endTime,
            buildingId: event.building.id,
            title: event.building.name
        })
    }

    async function handleCalendarModalEventCancellation(event: WithEventId, eventType: string): Promise<Result<null>> {


        const creationResult: Result<null> =
            eventType.toLowerCase() == "unavailable" ? await eventClient.deleteUnavailability(event.eventId) : await eventClient.cancelEvent(event)


        await refreshEvents()

        return creationResult
    }

    function cleanModalState() {
        setUpdateEvent(undefined)
        setNewEventDates(undefined)
        setShowModal(false)
        setShowCancelModal(false)
    }

    function handleDateSelect(selectInfo: DateSelectArg) {
        cleanModalState()
        setNewEventDates(new DateRange(DateTime.fromJSDate(selectInfo.start), DateTime.fromJSDate(selectInfo.end)))
        setShowModal(true)
    }

    function allowSelect(selectInfo: DateSelectArg) {
        cleanModalState()
        const start = DateTime.fromJSDate(selectInfo.start)
        return start.toUnixInteger() > now().toUnixInteger()

    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
    function handleEventClick(clickInfo: EventClickArg) {

        if (allowModal) {
            cleanModalState()
            const event = clickInfo.event;
            const {
                eventId,
                type,
                buildingId,
                eventStatus
            }: CalendarEventExtendedProps = event.extendedProps as CalendarEventExtendedProps


            const isEditable = type == 'Unavailable' ? entitlements.has(Permission.WRITE_UNAVAILABILTY) : entitlements.has(Permission.WRITE_EVENT)

            if (isEditable) {
                setUpdateEvent({
                    building: buildings.find(b => b.id == buildingId)!,
                    startTime: DateTime.fromJSDate(event.start!),
                    endTime: DateTime.fromJSDate(event.end!),
                    eventId: eventId,
                    type: type,
                    title: event.title,
                    eventStatus: eventStatus,
                })
                setShowModal(true)
            } else if (type == 'Event' && entitlements.has(Permission.WRITE_UNAVAILABILTY)) {
                setUpdateEvent({
                    building: buildings.find(b => b.id == buildingId)!,
                    startTime: DateTime.fromJSDate(event.start!),
                    endTime: DateTime.fromJSDate(event.end!),
                    eventId: eventId,
                    type: type,
                    title: event.title,
                    eventStatus: eventStatus,
                })
                setShowCancelModal(true)
            }
        }
    }


    function renderEventContent(eventContent: EventContentArg) {
        return eventContent.event.end && eventContent.event.start && ((eventContent.event.end.getTime() - eventContent.event.start.getTime()) > 45 * 60 * 1000) ?
            (
                <div data-testid={`event-${eventContent.event.id}`} className={'long-event'}>
                    <b>{eventContent.event.title}</b><span> </span>
                    {eventContent.event.extendedProps.type}
                    {eventContent.event.extendedProps.shouldBeWorried ? <CircleAlertIcon /> : null}
                    <br></br>
                    {eventContent.timeText}
                </div>
            ) : <div data-testid={`event-${eventContent.event.id}`} className={'short-event'}>
                {eventContent.event.extendedProps.shouldBeWorried ? <CircleAlertIcon /> : null}
                <span> </span>
                <b>{eventContent.event.title}</b>&nbsp;
                {eventContent.event.extendedProps.type}
            </div>
    }

    async function handleEventCancellationRequest() {
        notificationClient.requestCancellation({ eventId: parseInt(updateEvent!.eventId) })
    }

    return <>
        <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, luxonPlugin]}
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'timeGridWeek timeGridDay'
            }}
            initialView='timeGridWeek'
            firstDay={1}
            initialDate={now().toISO()}
            datesSet={useDebounceCallback(setCalendarInfo,300)}
            editable={false}
            selectable={allowModal}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            allDaySlot={false}
            events={events} // alternatively, use the `events` setting to fetch from a feed
            selectAllow={allowSelect}
            select={handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={handleEventClick}
            timeZone='Europe/London'
            height='auto'
            locale={enGbLocale}
        />
        {allowModal && showCancelModal && updateEvent &&
            <CancelEventModal
                show={true}
                setShow={setShowCancelModal}
                eventStart={updateEvent.startTime}
                eventEnd={updateEvent.endTime}
                building={updateEvent.building}
                handleEventCancellationRequest={handleEventCancellationRequest}
            />
        }
        {allowModal && showModal && updateEvent &&
            <EditEventModal eventTypes={eventTypes}
                eventUpdate={handleCalendarModalEventUpdate}
                retrieveValidationInfo={retrieveValidationInfo}
                eventCancellation={handleCalendarModalEventCancellation} buildings={buildings}
                initialShow={showModal} initialEvent={updateEvent} />}
        {allowModal && showModal && newEventDates?.start && newEventDates.end &&
            <CreateEventModal buildings={buildings} eventCreation={handleCalendarModalEventCreation}
                eventTypes={eventTypes}
                initialStart={newEventDates.start} initialEnd={newEventDates.end}
                initialShow={showModal}
                retrieveValidationInfo={retrieveValidationInfo} />}
    </>
}
