import {FloatingLabel} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import * as React from "react";


interface DatePickerProps {
    label: string
    testId: string
    onChange: (e) => void
    value: string
    dateRef: React.Ref<HTMLInputElement>
    minDate?: string
    maxDate?: string
    className?:string
    includeTime?: boolean
    disabled?: boolean
}

export const DatePicker = ({dateRef, label, testId, onChange, value = '', minDate, maxDate, className, includeTime = false, disabled = false}: DatePickerProps) => {
    const inputType = (includeTime)?'datetime-local':'date'
    return <div className={className}>
        <FloatingLabel label={label} className="mb-3">
            <Form.Control ref={dateRef}
                          type={inputType}
                          placeholder={label}
                          value={value}
                          data-testid={testId}
                          required={true}
                          onChange={onChange}
                          min={minDate}
                          max={maxDate}
                          disabled={disabled}
                          />
        </FloatingLabel>
    </div>
}
