import { AsyncComponentWithEntitlement} from "../common/AsyncComponent";
import { EitherUser, UserClient } from "../../client/UserClient";
import { useParams } from "react-router-dom";
import { ErrorMessage } from "../common/ErrorMessage";
import { EditUserForm } from "../EditUserForm";
import {Failure} from "../../client/Result";
import { Permission } from "../../Permission";
import {Entitlement} from "../../Entitlement";

interface EditUserAdminPageProps {
    userClient: UserClient
}

export const EditUserAdminPage = ({ userClient }: EditUserAdminPageProps) => {

    const { email } = useParams()

    const  loaderFunction =  (email: string) => async (entitlement: Entitlement) => {
        const user = await userClient.retrieveUserByEmail(email)

        if (entitlement.has(Permission.WRITE_USERS)) {
            const roles = await userClient.loadAllRoles()
            const organisations = await userClient.loadAllOrganisations()

            return organisations.flatMap(
                organisations => roles.flatMap(
                    roles => user.map(
                        user => ({roles: roles, user: user, organisations: organisations}))))

        } else if (entitlement.has(Permission.WRITE_CLIENT_USERS)) {
            return user.map(u => ({roles: [], organisations: [], user: u}))
        }
        return Promise.resolve(new Failure('No permissions'))
    }

    return (email ? <AsyncComponentWithEntitlement loaderFunction={loaderFunction(email)} dataPropName={'initialData'}>
        <EditUserForm initialData={{ roles: [], user: {} as EitherUser, organisations: [] }} onSubmit={user => userClient.updateUser(user)} />
    </AsyncComponentWithEntitlement> : <ErrorMessage error={Error('You need a valid user id')} />)
}
