interface ApiError {
    message: string
}

interface ApiErrors {
    errors: ApiError[]
}

export async function safeJson<T>(response: Response): Promise<T | string> {
    try {
        return await response.clone().json()
    } catch (e) {
        console.log(e)
        const asText = await response.text();
        console.error('Could not parse json', asText)
        return asText
    }
}


export async function parseResponseApiErrors(response: Response): Promise<ApiError[] | null> {
    if (response.ok) return null
    else {
        const apiErrorsOrErrorResponseBody: ApiErrors | string = await safeJson<ApiErrors>(response)
        if (typeof apiErrorsOrErrorResponseBody === 'string') {
            const apiError: ApiError = {message: `Failure response ${response.status}: ${apiErrorsOrErrorResponseBody}`}
            return [apiError]
        } else {
            return apiErrorsOrErrorResponseBody.errors
        }
    }
}
