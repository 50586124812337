
export type Action = 'read' | 'write'

export abstract class Permission {
    resource: string
    action: Action

    static READ_METERING: Permission = { action: "read", resource: "readings" }
    static READ_BILLING: Permission = { action: 'read', resource: 'billing' }
    static WRITE_ONBOARDING: Permission = { action: 'write', resource: 'onboarding' }
    static READ_MONITOR: Permission = { action: 'read', resource: 'monitor' }
    static READ_EVENT: Permission = { action: 'read', resource: 'event' }
    static READ_EVENT_DETAILS: Permission = { action: 'read', resource: 'eventDetails' }
    static WRITE_EVENT: Permission = { action: 'write', resource: 'event' }
    static READ_DEVICE: Permission = { action: 'read', resource: 'device' }
    static WRITE_DEVICE: Permission = { action: 'write', resource: 'device' }
    static READ_BUILDING: Permission = { action: 'read', resource: 'building' }
    static WRITE_BUILDING: Permission = { action: 'write', resource: 'building' }
    static READ_READINGS: Permission = { action: 'read', resource: 'readings' }
    static WRITE_READINGS: Permission = { action: 'write', resource: 'readings' }
    static WRITE_USERS: Permission = { action: 'write', resource: 'users' }
    static WRITE_CLIENT_USERS: Permission = { action: 'write', resource: 'clientUsers' }
    static WRITE_ORG: Permission = { action: 'write', resource: 'org' }
    static WRITE_UNAVAILABILTY : Permission = {action: 'write', resource:'unavailability'}
    static READ_UNAVAILABILITY : Permission = {action: 'read', resource:'unavailability'}
    static CONTACT_US: Permission = {action: 'write', resource:'contactSupport'}
}
