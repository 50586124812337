/* eslint-disable-next-line @typescript-eslint/no-var-requires */
import { Auth0Provider, useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Config } from "./environments/config";
import { AuthContextProvider, UserInfo } from "./AuthContext";
import { Permission } from "./Permission";
import { Entitlement } from "./Entitlement";


export interface IdentityProviderProps {
    config: Config
    children: JSX.Element
}

export const IdentityProvider = ({config, children}: IdentityProviderProps) => <Auth0Provider
    domain={config.AUTH0_DOMAIN}
    clientId={config.AUTH0_CLIENT_ID}
    cacheLocation={"localstorage"}
    useRefreshTokens={true}
    useRefreshTokensFallback={true}
    authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "/opstools"
    }}>
    <Auth0TokenProvider children={children}/>
</Auth0Provider>


const Auth0TokenProvider = ({children}) => {
    const {getAccessTokenSilently, getIdTokenClaims, logout} = useAuth0()


    async function userSupplier(): Promise<UserInfo> {
        try {
            const token = await getAccessTokenSilently()
            const claims = await getIdTokenClaims()
            const rawPermissions = claims ? claims["https://oaktreepower.co.uk/permissions"] ?? [] : []

            const permissions: Permission[] =
                rawPermissions
                    .map(str => {
                        const parts = str.split(':');
                        return parts.length == 2 ? [{action: parts[0], resource: parts[1]}] : []
                    }).flat();

            return {
                token,
                entitlement: Entitlement.fromPermissions(permissions)
            }
        } catch (error) {
            console.log('Failed to acquireTokenSilent, but interaction is not required', error)
            throw error
        }
    }

    const logOutAndRedirect = () => logout({logoutParams: {returnTo: window.location.origin}})

    const AuthedRoutes = withAuthenticationRequired(() => children);
    return <AuthContextProvider auth={{userInfo: userSupplier, logout: logOutAndRedirect}}>
        <AuthedRoutes/>
    </AuthContextProvider>
}
