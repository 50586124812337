import {Constraint} from "../client/EventScheduleClient";
import React from "react";
import {Row} from "react-bootstrap";
import {TextField} from "./OnboardingForm";


interface BuildingEventConstraintsProps {
    constraint: Constraint
}

export const BuildingEventConstraints = ({constraint}: BuildingEventConstraintsProps) => {

    const dailyEvents= `${constraint.dailyEvents.length}/${constraint.maxEvents}`
    const usedMinutes = constraint.dailyEvents.map(e => e.end.diffMinutes(e.start)).reduce((total, num)=> total+num, 0);
    const totalMinutes= `${usedMinutes}/${constraint.totalDailyRunTime}`

    return <div data-testid={'eventConstraint'}>
        <Row>
            <TextField displayName={'Number Of Events Today'} disabled={true} required={false} handleChange={()=>{return}} name={dailyEvents} testId={'dailyEvents'} value={dailyEvents}/>
            <TextField displayName={'Daily Limit (mins)'} disabled={true} required={false} handleChange={()=>{return}} name={totalMinutes} testId={'totalMinutes'} value={totalMinutes}/>
        </Row>
        <Row>
            <TextField displayName={'Max Event Length (mins)'} disabled={true} required={false} handleChange={()=>{return}} name={`${constraint.maxEventDuration}`} testId={'maxEventLength'} value={`${constraint.maxEventDuration}`}/>
            <TextField displayName={'Recovery Time (mins)'} disabled={true} required={false} handleChange={()=>{return}} name={`${constraint.recoveryTime}`} testId={'recoveryTime'} value={`${constraint.recoveryTime}`}/>
        </Row>
    </div>
}
