import { AsyncComponentWithEntitlement} from "../common/AsyncComponent";
import { Organisation, Role, UserClient } from "../../client/UserClient";
import { CreateFormInitialData, CreateUserForm } from "../CreateUserForm";
import {Failure, Result, Success} from "../../client/Result";
import { Permission } from "../../Permission";
import {Entitlement} from "../../Entitlement";

interface CreateUserAdminPageProps {
    userClient: UserClient
}

export const CreateUserAdminPage = ({ userClient }: CreateUserAdminPageProps) => {

    async function loaderFunction(entitlement: Entitlement): Promise<Result<CreateFormInitialData>> {
        if (entitlement.has(Permission.WRITE_USERS)) {
            const roles = await userClient.loadAllRoles()
            const organisations = await userClient.loadAllOrganisations()

            return organisations.flatMap(
                orgs => roles.map((rs: Role[]) => ({ roles: rs, organisations: orgs }))
            )
        } else if(entitlement.has(Permission.WRITE_CLIENT_USERS)) {
            return new Success({ roles: [] as Role[], organisations: [] as Organisation[] })
        }

        return Promise.resolve(new Failure('No permissions'))
    }

    return <AsyncComponentWithEntitlement loaderFunction={loaderFunction} dataPropName={'initialData'}>
        <CreateUserForm initialData={{ roles: [], organisations: [] }} onSubmit={user => userClient.createUser(user)} /></AsyncComponentWithEntitlement>
}
