import React from 'react';
import {Result} from "../../client/Result";
import {CardSection} from "./Card";
import {UploadButton} from "./UploadButton";

interface UploadHalfHourlyConsumptionProps {
    onFileUpload: (file: File) => Promise<Result<null>>
    className?: string
}

export const UploadHalfHourlyConsumption: React.FC<UploadHalfHourlyConsumptionProps> = ({onFileUpload, className}) => {
    return <CardSection className={className} header='Upload Half Hourly Consumption' data-testid={'fileUpload'}>
        <UploadButton onFileUpload={onFileUpload}/>
    </CardSection>
}
