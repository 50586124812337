import {BaseClient} from "./BaseClient";
import {DeviceToMeterMapping} from "../components/DeviceInputsToMetersLinkingCard";
import {Result} from "./Result";

export interface DeviceRegistration {
    deviceId: string
    siteId: string
    buildingId?: number | null
    meterMpans?: string[]
    accessKey: string
    defaultImageMeterIds: string[]
}

export interface Device {
    deviceId: string
    fake: boolean
}

export class DeviceClient {

    baseClient: BaseClient

    constructor(baseClient: BaseClient) {
        this.baseClient = baseClient
    }

    async registerDevice(): Promise<DeviceRegistration> {
        const response = await this.baseClient.post('/tooling/api/device', {});

        if (response.ok) return response.json()
        else throw Error(`Failed to register new device: ${response}`)
    }

    retrieveDevices(): Promise<Result<Device[]>> {
        return this.baseClient.getOkP(`/tooling/api/device`);
    }

    async getDeviceDetails(deviceId: string): Promise<DeviceRegistration | null> {
        const response = await this.baseClient.get(`/tooling/api/device/${deviceId}`);
        if (response.ok)
            return response.json()
        else
            return null
    }

    async retrieveDeviceKey(deviceId: string): Promise<DeviceRegistration> {
        return await this.baseClient.getOk<DeviceRegistration>(`/tooling/api/device/${deviceId}`)
    }

    async linkDeviceInputsToMpans(deviceId: string, mappings: DeviceToMeterMapping[]): Promise<boolean> {
        const requestArray: {mpan: string, iotHubDeviceId: string, imageMeterId: string}[] = mappings.map((mapping) =>{
            return {mpan: mapping.mpan.id, iotHubDeviceId: deviceId, imageMeterId: mapping.deviceInput.imageMeterId}
        })
        const response = await this.baseClient.post('/tooling/api/deviceMpanMapping/bulk', requestArray)
        return response.ok
    }

    async linkDeviceToBuilding(deviceId: string, buildingId: number): Promise<boolean> {
        const response = await this.baseClient.put(`/tooling/api/building/${buildingId}/linkedDevice/${deviceId}`)
        return response.ok
    }
}
