import { Permission } from "./Permission"
import { AppRoute } from "./Routes"
import equal from "deep-equal"

export abstract class Entitlement {
    isLoading: () => boolean
    can: (route: AppRoute) => boolean
    has: (permission: Permission) => boolean
    list: Permission[]

    static superAdmin: Entitlement = {isLoading: ()=>false, can: () => true, has: () => true, list:[] }
    static loser: Entitlement = {isLoading: ()=>false, can: () => false, has:  () => false, list:[{action:'write', resource:'bob'}] }

    static fromPermissions(userPermissions: Permission[]): Entitlement  {
        const has = (permission: Permission) => userPermissions.some(p => equal(p, permission))
        return {
            isLoading: ()=>false,
            can: (route: AppRoute) => route.permissions ? route.permissions.some(routePermission => has(routePermission)) : true,
            has,
            list:userPermissions
        }
    }
}

export class Loading extends  Entitlement{
    isLoading = () => true
    has = () => false
    can = ()=> false
    list =[]
}