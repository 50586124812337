import {EventScheduleClient} from "../../client/EventScheduleClient";
import {useEffect, useState} from "react";
import {EventSchedule, EventSchedules, Schedule} from "../EventSchedule";
import * as React from "react";

export interface SchedulePageProps{
    eventScheduleClient: EventScheduleClient
}

export const SchedulePage = ({eventScheduleClient}: SchedulePageProps) =>{
    const [schedules, setSchedules] = useState<EventSchedule[] | null>(null)

    useEffect(() => {
        eventScheduleClient
            .getAllEventSchedules()
            .then((s) => {
                setSchedules(s)
            })

    },[eventScheduleClient])

    return <div>
        {schedules ? <EventSchedules eventSchedule={schedules} scheduleEvents={(events: Schedule[]) => eventScheduleClient.scheduleEvents(events)}/> : <>Loading Schedules</>}
    </div>
}
