import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { PropsWithChildren, useState } from "react";
import { CrossIcon } from "../icons/Icons";
import MuiAlert from '@mui/material/Alert';
import { AlertColor } from "@mui/material/Alert/Alert";
import { useLocation } from "react-router-dom";
import {CopyToClipboardButton} from "./CopyToClipboardButton";



interface SimpleSnackbarProps {
    message: string
    show: boolean
    setShow
    severity: AlertColor
    autoHide: boolean
    'data-testid'?: string
}


function SimpleSnackbar({ message, severity, show, setShow, autoHide, 'data-testid': dataTestId }: SimpleSnackbarProps) {

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setShow(false);
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose} data-testid={'snackbarCloseButton'}>
                Close
            </Button>
            {severity=='error' && <CopyToClipboardButton content={message}/>}
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                data-testid={'snackbarIconCloseButton'}
                onClick={handleClose}
            >
                <CrossIcon />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div data-testid={dataTestId}>
            <Snackbar
                open={show}
                autoHideDuration={autoHide? 6000: null}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MuiAlert elevation={6} onClose={handleClose} severity={severity} sx={{ minWidth: 500 }} action={action}>
                    {message}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

export interface UserAlertProps {
    show: boolean
    setShow: (boolean) => void
    alertInfo?: AlertInfo
}
export interface AlertInfo {
    message: string
    type: UserAlertType
}

export type UserAlertType = 'success' | 'error';

export const UserAlert = ({  show, setShow, alertInfo }: UserAlertProps) => {

    switch (alertInfo?.type) {
        case 'success': {
            return <SimpleSnackbar autoHide={true} show={show} setShow={setShow} message={alertInfo.message} severity={'success'} data-testid='snackbar-success'/>

        }
        case 'error': {
            return <SimpleSnackbar autoHide={false} show={show} setShow={setShow} message={alertInfo.message} severity={'error'} data-testid='snackbar-error'/>
        }

        default:{
            return <SimpleSnackbar autoHide={false} show={show} setShow={setShow} message={'Report to Support - unexpected notification state'} severity={'error'} data-testid='snackbar-error'/>
        }
    }
}

export const UserAlertFromLocation = ({ children }: PropsWithChildren) => {
    const { userAlert } = useLocation()?.state ?? {}
    window.history.replaceState({}, document.title)

    const [show, setShow] = useState<boolean>(true)

    return userAlert ? <>
        <UserAlert show={show} setShow={setShow} alertInfo={userAlert} />
        {children}
    </> : <>{children}</>
}