import { CardSection } from "./common/Card";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { TypedDateRangePicker } from "./common/DateRangePicker";
import {DownloadCSVButton} from "./common/SubmitButton";
import { DateTime } from "../time/DateTime";
import { Result } from "../client/Result";
import {Select, SelectOption} from "./common/MuiSelect";
import {MeterApi} from "../client/MeterClient";


interface MeterDataProps {
    meters: MeterApi[]
    download: (mpan: string, start: string, end: string) => Promise<Result<void>>
    header: string
    className?: string
}

export const MeterData = ({ meters, download, className, header }: MeterDataProps) => {
    const [mpan, setMpan] = useState<string>()
    const [startDate, setStartDate] = useState<DateTime>()
    const [endDate, setEndDate] = useState<DateTime>()
    const [dateRangeValid, setDateRangeValid] = useState(false)

    const [validated, setValidated] = useState(false);


    const options: { id: string, label: string }[] = meters.map(m =>({ id: m.mpan, label: m.description }))

    const [errorMessage, setErrorMessage] = useState<string>()

    async function handleSubmit(event) {
        const form = event.currentTarget
        setErrorMessage(undefined)
        event.preventDefault()
        setValidated(true)
        if (form.checkValidity() === true && dateRangeValid && startDate && endDate) {
            const submissionResult = await download(mpan!, startDate.toISODate(), endDate.toISODate())
            submissionResult.fold(
                () => undefined,
                err => setErrorMessage(`An error occurred - #ask-tech for support. ${err}`)
            )
        } else {
            form.reportValidity()
        }
    }

    return <CardSection className={className} header={header}>

        <Form noValidate validated={validated}>
            <Select className='mb-3' label='mpan'  data-testid='mpanId' initialValue={options.find(it=> it.id === mpan)}
                onChange={(e:SelectOption) => {
                    setErrorMessage(undefined)
                    setMpan(e.id)
                }}
                options={options} />
            <TypedDateRangePicker start={startDate} end={endDate} onEndChange={setEndDate} onStartChange={setStartDate} setErrorMessage={setErrorMessage} reportIsValid={setDateRangeValid} />
            <DownloadCSVButton dataTestId='meterDataSubmitButton'
                disabled={!(mpan && startDate && endDate && (startDate.toMillis() <= endDate.toMillis()))}
                onSubmit={handleSubmit}/>
            {errorMessage &&
                <Alert data-testid='errorMessage' variant='danger'>{errorMessage}</Alert>}
        </Form>
    </CardSection>
}
