import {BaseClient} from "./BaseClient";
import { Result } from "./Result";

export interface RequestCancellationBody {
    eventId: number
}

export class NotificationClient {

    baseClient: BaseClient

    constructor(baseClient: BaseClient) {
        this.baseClient = baseClient
    }

    async requestCancellation(body: RequestCancellationBody): Promise<Result<null>> {
        return await this.baseClient.postOk('/tooling/api/notification/requestEventCancellation', body)
    }

}
