import { CardSection } from "./common/Card";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Building } from "./BuildingStatus";
import { DateRangePicker } from "./common/DateRangePicker";
import { ISODateRange } from "../ISODateRange";
import {DownloadCSVButton} from "./common/SubmitButton";
import {BuildingInput} from "./BuildingInput";
import { Result } from "../client/Result";
import {Select, SelectOption} from "./common/MuiSelect";


interface BillingExplanationProps {
    buildingsSource: Building[],
    downloadBaseline: (buildingId: number, range: ISODateRange, methodology: baseLineMethodology) => Promise<Result<void>>
    header: string
    className?: string
}

export type baseLineMethodology = 'OldPreviousHhAverage'| 'PreviousHhAverage' | 'PreviousHistoryProfile' | 'FiveDayHistoryProfile'

export const BillingExplanation = ({
    buildingsSource,
    downloadBaseline: downloadHistoricBaseLine,
    className,
    header
}: BillingExplanationProps) => {

    const [building, setBuilding] = useState<Building>()
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [dateRangeValid, setDateRangeValid] = useState(false)
    const [baselineMethodology, setBaselineMethodology] = useState<baseLineMethodology>()

    const [validated, setValidated] = useState(false);

    const [errorMessage, setErrorMessage] = useState<string>()

    async function handleSubmit(event) {
        const form = event.currentTarget
        setErrorMessage(undefined)
        event.preventDefault()
        setValidated(true)
        if (form.checkValidity() === true && dateRangeValid && building && baselineMethodology) {
            const submissionResult = await downloadHistoricBaseLine(building.id, new ISODateRange(startDate, endDate), baselineMethodology)
            submissionResult.fold(
                ()  => undefined,
                err => setErrorMessage(`An error occurred - #ask-tech for support. ${err}`)
            )
        } else {
            form.reportValidity()
        }
    }

    return <CardSection className={className} header={header}>
        <Form noValidate validated={validated}>
            <BuildingInput className={'mb-3'} buildings={buildingsSource} onBuildingChange={b => {
                setErrorMessage(undefined)
                setBuilding(b)
            }} />
            <Select className={'mb-3'}
                label={'Baseline'} data-testid={'test-id-baseLine'}
                initialValue={baselineMethodology? {id: baselineMethodology.toString(), label: baselineMethodology.toString()} : undefined}
                onChange={(e: SelectOption) => setBaselineMethodology(e.id as baseLineMethodology)}
                options={[
                    { id: 'OldPreviousHhAverage', label: 'OldPreviousHhAverage' },
                    { id: 'PreviousHhAverage', label: 'PreviousHhAverage' },
                    { id: 'PreviousHistoryProfile', label: 'PreviousHistoryProfile' },
                    { id: 'FiveDayHistoryProfile', label: 'FiveDayHistoryProfile' }
                ]} />
            <DateRangePicker start={startDate} end={endDate} onEndChange={(end) => setEndDate((end))}
                onStartChange={(start) => setStartDate(start)}
                setErrorMessage={error => (setErrorMessage(error))}
                reportIsValid={valid => setDateRangeValid(valid)} />
            <DownloadCSVButton dataTestId='download-Billing-Button'
                disabled={!(building && startDate && endDate && (startDate <= endDate))}
                onSubmit={e => handleSubmit(e)}/>
            {errorMessage &&
                <Alert data-testid='errorMessage' variant='danger'>{errorMessage}</Alert>}
        </Form>
    </CardSection>
}
