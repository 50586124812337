import {DeviceClient, DeviceRegistration} from "../../client/DeviceClient";
import {useParams} from "react-router-dom";
import {DeviceInputsToMetersLinkingCard} from "../DeviceInputsToMetersLinkingCard";
import * as React from "react";
import {Result} from "../../client/Result";

export interface DeviceInputsToMetersLinkingPageProps {
    deviceClient : DeviceClient
}

export const DeviceInputsToMetersLinkingPage = ({deviceClient}: DeviceInputsToMetersLinkingPageProps) => {
    const { deviceId} = useParams()

    async function retrieveDeviceIds(): Promise<Result<string[]>> {
        const devices = await deviceClient.retrieveDevices()
        return devices.map(ds => ds.map(d => d.deviceId))
    }

    function retrieveDeviceDetails(deviceId: string): Promise<DeviceRegistration | null> {
        return deviceClient.getDeviceDetails(deviceId)
    }

    return <div>
        <DeviceInputsToMetersLinkingCard onSubmit={(deviceId, mappings) => deviceClient.linkDeviceInputsToMpans(deviceId, mappings)}
                                         deviceId={deviceId}
                                         devicesSource={() => retrieveDeviceIds()}
                                         deviceDetailsSource={(deviceId) => retrieveDeviceDetails(deviceId)}
        />
    </div>
}
