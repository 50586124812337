import React from 'react';

type Props = {
    error: Error;
} ;

export const ErrorMessage: React.FC<Props> = ({ error,   }: Props) => {

    return (
        <>
            <div className="error-message"  data-testid={'error'}>
                {error.message}
            </div>
        </>
    );
};