import {DeviceClient, DeviceRegistration} from "../client/DeviceClient";
import {BuildingData, MonitorData} from "./BuildingStatus";
import {TestScheduleError} from "../client/EventScheduleClient";
import {Card} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {CopyToClipboardButton} from "./common/CopyToClipboardButton";
import {CardSection} from "./common/Card";
import {useNavigate} from "react-router-dom";
import {buildingStatus, deviceConfiguration} from "../Routes";
import {SubmitButton} from "./common/SubmitButton";


interface DeviceRegistrationDisplayProps {
    deviceRegistration: DeviceRegistration
    done: () => Promise<void>
}

const DeviceRegistrationDisplay = ({deviceRegistration, done}: DeviceRegistrationDisplayProps) =>
    <>
        <Card.Title>Configure new device - Please set it up with all the following details:</Card.Title>
        <Form>
            <Form.Group>
                <Form.Label>Site id</Form.Label>
                <InputGroup>
                    <Form.Control data-testid={'device-config-site-id'} type="text" readOnly value={deviceRegistration.siteId}/>
                    <CopyToClipboardButton content={deviceRegistration.siteId}/>
                </InputGroup>
            </Form.Group>
            <Form.Group>
                <Form.Label>IOT Hub Device Id:</Form.Label>
                <InputGroup>
                    <Form.Control data-testid={'device-config-device-id'} type="text" readOnly value={deviceRegistration.deviceId}/>
                    <CopyToClipboardButton content={deviceRegistration.deviceId}/>
                </InputGroup>
            </Form.Group>
            <Form.Group>
                <Form.Label>IOT Hub Device Primary key:</Form.Label>
                <InputGroup>
                    <Form.Control data-testid={'device-config-access-key'} type="text" readOnly value={deviceRegistration.accessKey}/>
                    <CopyToClipboardButton content={deviceRegistration.accessKey}/>
                </InputGroup>
            </Form.Group>
            <Form.Group>
                <Form.Label>Image meter ids</Form.Label>
                {deviceRegistration.defaultImageMeterIds.map((id, index) => {
                    return <Form.Group key={`meter-input-${index}`}>
                        <Form.Label>{`comsport ${index + 1}`}</Form.Label>
                        <InputGroup>
                            <Form.Control data-testid={`device-config-input-${index}`} type="text" readOnly value={id}/>
                            <CopyToClipboardButton content={id}/>
                        </InputGroup>
                    </Form.Group>
                })}
            </Form.Group>
        </Form>
        <SubmitButton dataTestId={'done-with-device-config'} onSubmit={done}>Entered</SubmitButton>
    </>


interface DeviceMonitorProps {
    buildingId: number
    monitorData: MonitorData
    scheduleTenMinuteEvent: (buildingId: number) => Promise<TestScheduleError | null>
}

const DeviceMonitor = ({buildingId, monitorData, scheduleTenMinuteEvent}: DeviceMonitorProps) => {
    return <>
        <Card.Title as="h5">Verify connectivity</Card.Title>
        <BuildingData data={monitorData}
                      scheduleEvent={() => scheduleTenMinuteEvent(buildingId)}/>
    </>
}


export interface DevicePreparationProps {
    deviceClient: DeviceClient
}

export const DevicePreparation = ({deviceClient}: DevicePreparationProps) => {
    const navigate = useNavigate()

    async function handleDeviceRegistrationClick() {
        const registration = await deviceClient.registerDevice()
        navigate(deviceConfiguration.withParams(registration.deviceId))
    }

    return <CardSection header={'New device'}>
             <SubmitButton onSubmit={handleDeviceRegistrationClick}>Register</SubmitButton>
    </CardSection>
}

interface DeviceRegistrationDisplayCardProps {
    deviceRegistration: DeviceRegistration
    title: string
}

export const DeviceRegistrationDisplayCard = ({deviceRegistration, title}: DeviceRegistrationDisplayCardProps) => {
        const navigate = useNavigate()

    async function handleDoneDisplayingRegistration(): Promise<void> {
        if (deviceRegistration?.buildingId) {
            navigate(buildingStatus.withParams(deviceRegistration.buildingId))
        }
    }

    return <CardSection header={title}><DeviceRegistrationDisplay deviceRegistration={deviceRegistration}
                                                                  done={handleDoneDisplayingRegistration}/>
    </CardSection>
}

interface DeviceMonitorCardProps {
    title: string
    buildingId: number
    monitorData: MonitorData
    scheduleTenMinuteEvent: (buildingId: number) => Promise<TestScheduleError | null>

}

export const DeviceMonitorCard = ({title, buildingId, monitorData, scheduleTenMinuteEvent}: DeviceMonitorCardProps) => {
    return <CardSection header={title}>
        <DeviceMonitor buildingId={buildingId}
                       monitorData={monitorData}
                       scheduleTenMinuteEvent={scheduleTenMinuteEvent}/>
    </CardSection>
}