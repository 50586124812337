import React, {createContext, PropsWithChildren, useContext} from 'react';

export class FeatureFlag{
    enabled: boolean
    constructor(enable: boolean) {
        this.enabled=enable
    }
}
// Create a context
const FeatureFlagContext: React.Context<FeatureFlag> = createContext(new FeatureFlag(false));
// Create a custom hook to access the context

export const useFeatureFlagContext = () => useContext(FeatureFlagContext);

interface FeatureFlagProviderProps extends PropsWithChildren{
    featureFlag : FeatureFlag
}
// Create a provider component to provide the datetime value
export const FeatureFlagProvider = ({ children, featureFlag }: FeatureFlagProviderProps) => (
    <FeatureFlagContext.Provider value={featureFlag}>{children}</FeatureFlagContext.Provider>
);