import { UserClient} from "../../client/UserClient";
import { Result } from "../../client/Result";
import { CrudForm } from "../common/CrudForm";
import { admin } from "../../Routes";

interface CreateOrgAdminPageProps{
    userClient: UserClient
}

export const CreateOrgAdminPage = ({userClient}: CreateOrgAdminPageProps) => {

    interface FormOrg {
        orgName: string
    }

    function handleSubmit(formUser: FormOrg): Promise<Result<null>> {
        const {orgName} = formUser
        if (orgName) {
            return userClient.createOrg(orgName)
        } else {
            throw new Error('Submitting user create without original fields')
        }
    }

    return <CrudForm redirectPath={admin.path} fields={[
        {key: 'orgName', label: 'Name', type: 'string', required:true},
    ]} onSubmit={handleSubmit}/>
}