import { useParams } from "react-router-dom"
import { Result } from "../../client/Result"
import { useEntitlements } from "../../EntitlementProvider"
import { CrudForm, FieldProp, MultiFieldProp } from "../common/CrudForm"
import { BuildingClient, TransmissionFeeType } from "../../client/BuildingClient"
import { useEffect, useState } from "react"
import { Mpan } from "../BuildingStatus"
import { SelectOption } from "../common/MuiSelect"
import { meteringOptionalRoute } from "../../Routes"
import { DateTime } from "../../time/DateTime"
import { FormClient, Supplier } from "../../client/FormClient"

interface CreateTariffPageProps {
    buildingClient: BuildingClient
    formClient: FormClient
}

interface TariffForm {
    transmissionFeeType: SelectOption
    startDate: DateTime
    endDate: DateTime
    pricePerKWh: number
    mpan: SelectOption
    supplier: SelectOption
}

export const CreateTariffPage = ({ buildingClient, formClient }: CreateTariffPageProps) => {

    const { buildingId } = useParams()
    const [mpans, setMpans] = useState<Mpan[]>()
    const [transmissionFeeTypes, setTransmissionFeeTypes] = useState<TransmissionFeeType[]>()
    const [suppliers, setSuppliers] = useState<Supplier[]>()

    const entitlement = useEntitlements()

    useEffect(() => {
        if (buildingId) {
            buildingClient.buildingData(Number(buildingId)).then(building => setMpans(building.mpans))
        }

        buildingClient.getFeeTariffTransmissionType().then(t => setTransmissionFeeTypes(t.fold(i => i, () => [])))
       
        formClient.retrieveSuppliers().then(s => setSuppliers(s))

    }, [buildingId, entitlement])

    function handleSubmit(tariffForm: Partial<TariffForm>): Promise<Result<null>> {
        const { transmissionFeeType, startDate, endDate, pricePerKWh, mpan, supplier } = tariffForm
        if (transmissionFeeType && startDate && endDate && pricePerKWh && mpan && supplier) {
            return buildingClient.createTariff({
                transmissionFeeType: TransmissionFeeType(transmissionFeeType.id),
                startDate: startDate.raw().toISODate()!,
                endDate: endDate.raw().toISODate()!,
                pricePerKWh,
                mpan: mpan.id,
                supplierId: Number(supplier.id)
            })
        } else {
            throw new Error('Submitting user create without original fields')
        }
    }

    const tariff: (FieldProp<TariffForm> | MultiFieldProp<TariffForm>)[] = [
        { key: `transmissionFeeType`, label: 'TransmissionFeeType', type: 'select', options: transmissionFeeTypes?.map(tft => ({ id: tft, label: tft })), required: true },
        { key: `startDate`, label: 'Start Date', type: 'date', required: true },
        { key: `endDate`, label: 'End Date', type: 'date', required: true },
        { key: `pricePerKWh`, label: 'Price £/KWH', type: 'number', required: true },
        { key: `mpan`, label: 'MPAN', type: 'select', options: mpans?.map(m => ({ id: m, label: m })), required: true },
        { key: `supplier`, label: 'Supplier', type: 'select', options: suppliers?.map(s => ({ id: s.id.toString(), label: s.name })), required: true },
    ]

    return transmissionFeeTypes && mpans && suppliers ?
        <CrudForm redirectPath={meteringOptionalRoute.withParams(Number(buildingId))} fields={tariff} onSubmit={handleSubmit} /> : <></>

}
