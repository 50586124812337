import {FormClient, SubmissionModel} from "../../client/FormClient";
import { UserClient } from "../../client/UserClient";
import {OnboardingForm} from "../OnboardingForm";
import * as React from "react";

export interface OnboardingPageProps {
    formClient: FormClient
    userClient: UserClient
}

export const OnboardingPage = ({formClient, userClient}: OnboardingPageProps) => <div>
    <OnboardingForm
        handleSubmit={(data: SubmissionModel) => formClient.postForm(data)}
        retrieveDeals={() => formClient.retrieveNewDeals()}
        retrieveSuppliers={() => formClient.retrieveSuppliers()}
        retrieveMops={() => formClient.retrieveMops()}
        retrieveOrgs={() => userClient.loadAllOrganisations().then(o => o.fold(a => a, () => []))}
    />
</div>
