import {BuildingClient} from "../../client/BuildingClient";
import { Building, MonitorData} from "../BuildingStatus";
import * as React from "react";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {DeviceMonitorCard} from "../DevicePreparation";
import {EventScheduleClient} from "../../client/EventScheduleClient";


export interface SingleBuildingStatusPageProps {
    buildingClient: BuildingClient
    eventScheduleClient: EventScheduleClient
}

export const SingleBuildingStatusPage = ({buildingClient, eventScheduleClient}: SingleBuildingStatusPageProps)=> {
    const { id } = useParams()
    const [monitorData, setMonitorData]= useState<MonitorData>()
    const [building, setBuilding]= useState<Building>()

    useEffect(()=>{
        if(id) {
            const buildingIdNumber = Number(id);
            buildingClient.retrieveMonitorData(buildingIdNumber).then(monitorData =>
                setMonitorData(monitorData)
            )
            buildingClient.buildingData(buildingIdNumber).then(building =>
                setBuilding(building)
            )
        }
    },[buildingClient, id])

    return <>
        {(building && monitorData) ? <DeviceMonitorCard
            buildingId={building?.id}
            monitorData={monitorData}
            scheduleTenMinuteEvent={eventScheduleClient.scheduleTestEvent.bind(buildingClient)}
            title={building.name}/> : <>Loading Status</>}
    </>
}
