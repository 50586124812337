import { Building } from "./BuildingStatus";
import { useEffect, useState } from "react";
import { Select, SelectOption } from "./common/MuiSelect";

export interface BuildingInputProps {
    buildings: Building[]
    building?: Building
    disabled?: boolean
    onBuildingChange: (b: Building) => void
    className?: string
    testId?: string
}

export const BuildingInput = ({ buildings, building, disabled, onBuildingChange, className, testId }: BuildingInputProps) => {
    const [internalBuildingId, setInternalBuildingId] = useState<number | undefined>(building?.id ?? undefined)
    const options = buildings.map(it => ({ ...it, label: it.name, id: it.id.toString() }));

    const initialValue = options.find(it => it.id === internalBuildingId?.toString()) ?? undefined

    useEffect(() => {
        setInternalBuildingId(building?.id)
    }, [building])


    return <Select className={className}
        label='Building'
        data-testid={testId ? testId : 'buildingInput'}
        initialValue={initialValue}
        disabled={disabled}
        onChange={(e: SelectOption) => {
            const newBuilding = buildings.find(b => b.id === Number(e.id))!;
            setInternalBuildingId(newBuilding.id)
            onBuildingChange(newBuilding)
        }}
        options={options} />
}

export interface StatefulSelectProps {
    options: { id: number | string, name: string }[]
    selected?: { id: number | string, name: string }
    onChange: (b: { id: number | string, name: string }) => void
    className?: string
    displayName: string
    testId?: string
    disabled?: boolean
}

export const StatefulSelect = ({ options, selected, onChange, displayName, testId = 'stateful-select', className, disabled = false }: StatefulSelectProps) => {
    const [internalSelected, setInternalSelected] = useState<number | string>()

    useEffect(() => {
        setInternalSelected(selected?.id)
    }, [selected])

    function handleChange(e: SelectOption) {
        const newSelection = options.find(b => b.id == e.id)!;
        setInternalSelected(newSelection.id)
        onChange(newSelection)
    }

    const selectableOptions = options.map(it => ({ id: it.id.toString(), label: it.name }))

    const initialVal = internalSelected ? selectableOptions.find(it => it.id === internalSelected.toString()) : undefined

    return <Select className={className} label={displayName} data-testid={testId} initialValue={initialVal}
        onChange={handleChange}
        options={selectableOptions}
        disabled={disabled} />
}
