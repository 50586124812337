import { FormClient } from "./client/FormClient";
import { FetchTransport } from "./client/ApiTransport";
import './App.scss';
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { BuildingClient } from "./client/BuildingClient";
import { DeviceClient } from "./client/DeviceClient";
import { BaseClient } from "./client/BaseClient";
import { EventScheduleClient } from "./client/EventScheduleClient";
import { routes } from "./Routes";
import { DataClient } from "./client/DataClient";
import { Config } from "./environments/config";
import { DateTimeProvider } from "./DateTimeContext";
import { DateTime } from "./time/DateTime";
import { useAuthContext } from "./AuthContext";
import { FeatureFlag, FeatureFlagProvider } from "./FeatureFlagContext";
import * as React from "react";
import { Page } from "./components/common/Page";
import { UserClient } from "./client/UserClient";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { primaryGreen } from "./Style";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useEntitlements } from "./EntitlementProvider";
import { NotificationClient } from "./client/NotificationsClient";
import {MeterClient} from "./client/MeterClient";


export interface AppProps {
    config: Config
}

export const App = ({ config }: AppProps) => {

    const authContext = useAuthContext()
    const entitlement = useEntitlements()

    const fetchTransport = new FetchTransport();
    const baseClient = new BaseClient(fetchTransport, authContext?.userInfo, config.API_HOST)
    const formClient = new FormClient(baseClient)
    const deviceClient = new DeviceClient(baseClient)
    const buildingClient = new BuildingClient(baseClient)
    const meterClient = new MeterClient(baseClient)
    const eventScheduleClient = new EventScheduleClient(baseClient)
    const dataClient = new DataClient(baseClient)
    const userClient = new UserClient(baseClient)
    const notificationClient = new NotificationClient(baseClient)

    const router = createBrowserRouter([
        {
            element: <Page><Outlet /></Page>,
            children: routes(formClient, deviceClient, buildingClient, meterClient, eventScheduleClient, notificationClient, dataClient, userClient, entitlement)
        }
    ]);

    const theme = createTheme({
            palette: {
                primary: {
                    main: primaryGreen,
                },
            },
            typography: {
                button: {
                    fontFamily: "system-ui, -apple-system, \"Segoe UI\", Roboto, \"Helvetica Neue\", \"Noto Sans\", \"Liberation Sans\", Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textTransform: "none",
                }
            }
        }
    );

    return <DateTimeProvider datetime={() => DateTime.now()}>
        <FeatureFlagProvider featureFlag={new FeatureFlag(config.ENABLE_FEATURE)}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={'en-gb'}>
                    <RouterProvider router={router} />
                </LocalizationProvider>
            </ThemeProvider>
        </FeatureFlagProvider>
    </DateTimeProvider>
}
