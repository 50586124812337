import { useParams } from "react-router-dom";
import { Failure, Result } from "../../client/Result";
import { useEntitlements } from "../../EntitlementProvider";
import { CrudForm } from "../common/CrudForm";
import { meteringOptionalRoute } from "../../Routes";
import {BuildingClient, ControlStrategyApi} from "../../client/BuildingClient";
import { useEffect, useState } from "react";

interface EditControlStrategyPageProps {
    buildingClient: BuildingClient
}

export const EditControlStrategyPage = ({ buildingClient }: EditControlStrategyPageProps) => {

    const { buildingId, controlStrategyId } = useParams()
    const [controlStrategy, setControlStrategy] = useState<ControlStrategyApi>();

    const entitlement = useEntitlements()

    useEffect(() => {
        if (controlStrategyId) {
            buildingClient.retrieveBuildingStrategy(Number(buildingId)).then(t => setControlStrategy(t.fold(t => t, () => undefined)))
        } else {
            setControlStrategy(undefined)
        }
    }, [controlStrategyId, entitlement])

    type SubmitFunction = (params: {
        maxTotalDurationMinutes: number
        maxRunDuration: number
        maxNumEvents: number
        saving: number
        recoveryDuration: number
        rampUpDownTime: number
    }) => Promise<Result<null>>

    const onSubmit: SubmitFunction = ({
                                          maxTotalDurationMinutes,
                                          maxRunDuration,
                                          maxNumEvents,
                                          saving,
                                          recoveryDuration,
                                          rampUpDownTime,   }) => {
      if(controlStrategy){
          const updatedBody = {...controlStrategy,
              maxTotalDurationMinutes:Number(maxTotalDurationMinutes),
              maxRunDuration:Number(maxRunDuration),
              maxNumEvents: Number(maxNumEvents),
              saving: Number(saving),
              recoveryDuration: Number(recoveryDuration),
              rampUpDownTime: Number(rampUpDownTime)

          };
          return buildingClient.updateBuildingStrategy(updatedBody)

        } else {
            return Promise.reject(new Failure("Tariff does not exist"))
        }
    }

    return controlStrategy && buildingId ?
        <CrudForm redirectPath={meteringOptionalRoute.withParams(Number(buildingId))} data={controlStrategy} fields={[
            { key: `maxTotalDurationMinutes`, label: 'Max Total Duration(m)', type: 'number', required: true },
            { key: `maxRunDuration`, label: 'Max Event Duration(m)', type: 'number', required: true },
            { key: `maxNumEvents`, label: 'Max Events (daily)', type: 'number', required: true },
            { key: `saving`, label: 'Total Power Saving (Kw)', type: 'number', required: true },
            { key: `recoveryDuration`, label: 'Minimum Recovery(m)', type: 'number', required: true },
            { key: `rampUpDownTime`, label: 'Ramp Up Time(m)', type: 'number', required: true },
        ]} onSubmit={onSubmit} /> : <></>

  
}
