import {primaryGreen} from "../../Style";

export const Logo = () =>
    <svg xmlns="http://www.w3.org/2000/svg" width="53.755" height="49.297" viewBox="0 0 53.755 49.297">
        <g id="home" transform="translate(0.345 -1.683)">
            <g id="Group_28" data-name="Group 28" transform="translate(0 0)">
                <path id="Path_6" data-name="Path 6"
                      d="M10.129,33.129a6.629,6.629,0,0,0,5.552,2.344,33.517,33.517,0,0,0,5.667-1.417c-2.444,3.6-9.876,3.357-12.649-.832,2.6-3.815,7.526-4.295,13.744-1.1l1.664-5.743.858.24a47.383,47.383,0,0,1-1.317,5.1,2.219,2.219,0,0,0,.125,2.407,9.715,9.715,0,0,1-.246,11.5,9.538,9.538,0,0,1-10.862,3.137,15.313,15.313,0,0,1-8.727-7.426C-6.47,23.121,4.883,1.493,24.752.086,35.779-.695,44.459,3.835,49.946,13.4c5.552,9.68,4.878,19.553-1,28.972a13.868,13.868,0,0,1-9.437,6.709c-4.059.739-7.393-.515-9.7-3.921a9.324,9.324,0,0,1-.193-10.688,2.732,2.732,0,0,0,.218-3.069,7.707,7.707,0,0,1-.825-3.874c.178-1.907-.416-3.043-2.2-3.649-7.465,3.938-13.781,2.745-16.8-3.205,4.064-3.949,10.028-2.572,14.234,3.382l1.731-1.117c-2.514-3.009-3.524-6.436-2.534-10.09.56-2.068,1.9-3.922,2.959-6,4.711,4.208,5.35,10.334,1.962,14.952l-.548-.077a8.021,8.021,0,0,1,.417-1.608,9.709,9.709,0,0,0-.823-10.689,3.691,3.691,0,0,0-.542-.5c-4.5,3.924-3.249,11.738,2.535,15.128,1.78-3.123,4.244-5.359,8.077-5.508a8.642,8.642,0,0,1,6.043,1.9c-1.564,4.477-8.616,7.455-12.38,5.037,2.222-.263,4.355-.166,6.212-.836,1.81-.653,3.339-2.092,5.138-3.284a7.555,7.555,0,0,0-9.907.316,7.818,7.818,0,0,0-1.139,10.282c6.375-2.942,10.357-2.6,13.5,1.17-2.534,4.05-9.83,4.712-12.7.961a36.12,36.12,0,0,0,5.858,1.386,6.515,6.515,0,0,0,5.407-2.362,8.2,8.2,0,0,0-8.43-1.673,8.135,8.135,0,0,0-5.756,6.837c-.947,6.817,5.037,11.667,11.512,9.343,5.265-1.891,8.079-6.125,9.919-11.079C56.544,20.976,47.8,5.027,31.62,1.492,18.321-1.414,4.276,7.94,1.674,21.527a26.578,26.578,0,0,0,3.777,20.08,14.279,14.279,0,0,0,7.279,6c4.231,1.493,8.352.1,10.508-3.549a8.648,8.648,0,0,0-1.85-11c-3.2-2.785-7.569-2.814-11.259.063m1.418-12.256c2.176,3.852,7.643,5.552,11.26,3.6-1.585-4.457-6.953-6.221-11.26-3.6"
                      transform="translate(-0.346 1.683)" fill="#fff"></path>
            </g>
        </g>
    </svg>


export const PrimaryLogo = () =>
        <svg xmlns="http://www.w3.org/2000/svg" width="53.755" height="49.297" viewBox="0 0 53.755 49.297">
            <g id="home" transform="translate(0.345 -1.683)">
                <g id="Group_28" data-name="Group 28" transform="translate(0 0)">
                    <path id="Path_6" data-name="Path 6"
                          d="M10.129,33.129a6.629,6.629,0,0,0,5.552,2.344,33.517,33.517,0,0,0,5.667-1.417c-2.444,3.6-9.876,3.357-12.649-.832,2.6-3.815,7.526-4.295,13.744-1.1l1.664-5.743.858.24a47.383,47.383,0,0,1-1.317,5.1,2.219,2.219,0,0,0,.125,2.407,9.715,9.715,0,0,1-.246,11.5,9.538,9.538,0,0,1-10.862,3.137,15.313,15.313,0,0,1-8.727-7.426C-6.47,23.121,4.883,1.493,24.752.086,35.779-.695,44.459,3.835,49.946,13.4c5.552,9.68,4.878,19.553-1,28.972a13.868,13.868,0,0,1-9.437,6.709c-4.059.739-7.393-.515-9.7-3.921a9.324,9.324,0,0,1-.193-10.688,2.732,2.732,0,0,0,.218-3.069,7.707,7.707,0,0,1-.825-3.874c.178-1.907-.416-3.043-2.2-3.649-7.465,3.938-13.781,2.745-16.8-3.205,4.064-3.949,10.028-2.572,14.234,3.382l1.731-1.117c-2.514-3.009-3.524-6.436-2.534-10.09.56-2.068,1.9-3.922,2.959-6,4.711,4.208,5.35,10.334,1.962,14.952l-.548-.077a8.021,8.021,0,0,1,.417-1.608,9.709,9.709,0,0,0-.823-10.689,3.691,3.691,0,0,0-.542-.5c-4.5,3.924-3.249,11.738,2.535,15.128,1.78-3.123,4.244-5.359,8.077-5.508a8.642,8.642,0,0,1,6.043,1.9c-1.564,4.477-8.616,7.455-12.38,5.037,2.222-.263,4.355-.166,6.212-.836,1.81-.653,3.339-2.092,5.138-3.284a7.555,7.555,0,0,0-9.907.316,7.818,7.818,0,0,0-1.139,10.282c6.375-2.942,10.357-2.6,13.5,1.17-2.534,4.05-9.83,4.712-12.7.961a36.12,36.12,0,0,0,5.858,1.386,6.515,6.515,0,0,0,5.407-2.362,8.2,8.2,0,0,0-8.43-1.673,8.135,8.135,0,0,0-5.756,6.837c-.947,6.817,5.037,11.667,11.512,9.343,5.265-1.891,8.079-6.125,9.919-11.079C56.544,20.976,47.8,5.027,31.62,1.492,18.321-1.414,4.276,7.94,1.674,21.527a26.578,26.578,0,0,0,3.777,20.08,14.279,14.279,0,0,0,7.279,6c4.231,1.493,8.352.1,10.508-3.549a8.648,8.648,0,0,0-1.85-11c-3.2-2.785-7.569-2.814-11.259.063m1.418-12.256c2.176,3.852,7.643,5.552,11.26,3.6-1.585-4.457-6.953-6.221-11.26-3.6"
                          transform="translate(-0.346 1.683)" fill={primaryGreen}></path>
                </g>
            </g>
        </svg>