

export abstract class Result<T> {
    abstract map<U>(f: (t: T) => U): Result<U>
    abstract flatMap<U>(f: (t: T) => Result<U>): Result<U>
    abstract fold<U, V>(onSuccess: (t: T) => U, onFailure: (t: string) => V): U | V
    abstract readonly __typename: 'success'|'failure'
}

export function isSuccess<T>(value: Result<T>): value is Success<T>  { return value.__typename == "success"}


export class Success<T> extends Result<T> {
    constructor(public value: T) {
        super()
    }

    readonly __typename = 'success'

    map<U>(f: (t: T) => U): Result<U> {
        return new Success(f(this.value))
    }

    fold<U, V>(onSuccess: (t: T) => U): U | V {
        return onSuccess(this.value)
    }

    flatMap<U>(f: (t: T) => Result<U>): Result<U> {
        return f(this.value);
    }
}

export class Failure extends Result<any> {
    constructor(public error: string) {
        super()
    }

    readonly __typename = 'failure'

    map<U>(): Result<U> {
        return this
    }

    fold<U, V>(onSuccess: (t: any) => U, onFailure: (t: string) => V): U | V {
        return onFailure(this.error)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    flatMap<U>(f: (t: any) => Result<U>): Result<U> {
        return this;
    }
}