import {
    DataGrid,
    GridColDef, GridRowId,
    GridRowsProp,
    GridToolbar
} from "@mui/x-data-grid";
import {ObjectWithId} from "./CrudTable";


export interface TableProps<T extends object> {
    data: T[]
    setSelectedData: (t: T | undefined) => void
    selectionModel: GridRowId[]
    setSelectionModel
    dataTestId?: string
}

export function getHeaders<T extends object>(data: T[]) {
    if(data[0]) {
        const columns: GridColDef[] = Object.keys(data[0]).map((v) => {
            return {field: v, headerClassName: 'table-column-header', headerName: v, flex: 1}
        })
        return columns;
    }
    return []
}

export function toRows<T extends object>(data: T[]) {
    const rows: GridRowsProp = data.map((v, i) => {
        const row = {id: i}
        const values = Object.entries(v)
        values.map((vv) => {
            const [k,v] = vv
            row[k] = v
        })
        return row
    })
    return rows;
}

export function getSelectedData<T extends ObjectWithId>(row, columns, data: T[]): T | undefined {
    const field = columns.find(f => f.headerName == 'queryableDataId')?.field ?? ''
    return data.find(u => u.queryableDataId == row[field])
}

export function SingleSelectTable<T extends ObjectWithId>({data, setSelectedData, selectionModel, setSelectionModel, dataTestId}: TableProps<T>) {
    const columns = getHeaders(data);
    const rows = toRows(data)

    return (
        <div style={{width: '100%'}} data-testid={dataTestId}>
            <DataGrid
                rows={rows}
                columns={columns}
                columnVisibilityModel={{queryableDataId: false}}
                pageSizeOptions={[5]}
                checkboxSelection
                hideFooterSelectedRowCount
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(selection) => {
                    if(selection.length == 0 ){
                        setSelectionModel([])
                        setSelectedData(undefined)
                    }
                    else if (selection.length > 1) {
                        const selectionSet = new Set(selectionModel);
                        const result = selection.filter((s) => !selectionSet.has(s));
                        setSelectionModel(result);
                        setSelectedData(getSelectedData(rows[selection[1]], columns, data))
                    } else {
                        setSelectedData(getSelectedData(rows[selection[0]], columns, data))
                        setSelectionModel(selection);
                    }
                }}
                initialState={{

                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                slots={{
                    toolbar: GridToolbar,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: {debounceMs: 500},
                    },
                }}
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                        display: "none"
                    }
                }}
             />
        </div>
    );
}
