import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {
    faCheck,
    faXmark,
    faSignOut,
    faArrowUpRightFromSquare,
    faEdit,
    faPlus,
    faBan,
    faEnvelopeOpen,
    faCircleExclamation,
    faUpload, faDownload,
} from "@fortawesome/free-solid-svg-icons";
import {SizeProp} from "@fortawesome/fontawesome-svg-core";

export const TrashIcon = () => <FontAwesomeIcon icon={faTrashCan}/>

export const CrossIcon = () => <FontAwesomeIcon icon={faXmark}/>
export const PlusIcon = () => <FontAwesomeIcon icon={faPlus}/>

export const TickIcon = () => <FontAwesomeIcon icon={faCheck}/>
export const EditIcon = () => <FontAwesomeIcon icon={faEdit}/>

export const UploadIcon = () => <FontAwesomeIcon icon={faUpload}/>
export const DownloadIcon = () => <FontAwesomeIcon icon={faDownload}/>

export const CircleAlertIcon = () => <FontAwesomeIcon data-testid="circle-exclamation-icon" icon={faCircleExclamation} />

export const BanIcon = () => <FontAwesomeIcon icon={faBan} />


export interface IconProps {
    size?: SizeProp
}
export const SignOutIcon = ({size}: IconProps) => <FontAwesomeIcon icon={faSignOut} size={size}/>
export const ContactUs = ({size}: IconProps) => <FontAwesomeIcon icon={faEnvelopeOpen} size={size}/>

export const ArrowUpFromSquare = ()=> <FontAwesomeIcon icon={faArrowUpRightFromSquare} color={'white'} size={'xl'}/>