import {BuildingClient, BuildingWithDeviceIds} from "../../client/BuildingClient";
import {Device, DeviceClient} from "../../client/DeviceClient";
import {useEffect, useState} from "react";
import {LinkBuildingToDevice} from "../LinkBuildingToDevice";
import * as React from "react";

export interface LinkingPageProps {
    buildingClient: BuildingClient
    deviceClient: DeviceClient
}

export const LinkingPage = ({buildingClient, deviceClient}: LinkingPageProps) => {

    const [devices, setDevices] = useState<Device[]>([])
    const [buildings, setBuildings] = useState<BuildingWithDeviceIds[]>([])

    useEffect(() => {
        deviceClient.retrieveDevices().then(retrievedDevices =>
        {
            retrievedDevices.fold(devices => setDevices(devices), msg => {throw Error(msg)})
        })
    }, [deviceClient])

    useEffect(() => {
        buildingClient.retrieveBuildingsWithDeviceIds().then(retrievedBuildings => setBuildings(retrievedBuildings))
    }, [buildingClient])

    return <div>
        <LinkBuildingToDevice devices={devices} buildings={buildings}
                              linkDeviceToBuilding={(deviceId, buildingId) => deviceClient.linkDeviceToBuilding(deviceId, buildingId)}
                              deviceClient={deviceClient} />
    </div>
}
