import {FloatingLabel} from "react-bootstrap";
import Form from "react-bootstrap/Form";

export interface RequiredPromptProps {
    required: boolean
    fieldName: string,
    testId: string
}

export const RequiredPrompt = ({required, fieldName, testId}: RequiredPromptProps) =>
    required ? <Form.Control.Feedback type="invalid" data-testid={`error-${testId}`}>
        Please choose a {fieldName}.
    </Form.Control.Feedback> : <></>


interface FieldProps {
    displayName: string
    name: string
    value?: string | number
    testId: string
    handleChange: (e: any) => void
    required?: boolean
}

export interface SelectFieldProps extends FieldProps {
    options: {id: number|string, name: string}[]
    disabled?: boolean
}

export const SelectField = ({
                                displayName,
                                name,
                                value = undefined,
                                testId,
                                options,
                                handleChange,
                                disabled,
                                required = true
                            }: SelectFieldProps) => {
    const value0 = value === undefined ? 'DEFAULT' : value

    return <>
        <FloatingLabel label={displayName} className="mb-3">
            <Form.Select
                value={value0}
                name={name}
                data-testid={testId}
                disabled={disabled}
                onChange={handleChange}
                // required
            >
                <option value="DEFAULT" disabled>Choose...</option>
                {options.map((option, index) => <option data-testid={`${testId}-option-${index}`} key={option.id} value={option.id}>{option.name}</option>)}
            </Form.Select>
        </FloatingLabel>
        <RequiredPrompt required={required} fieldName={displayName} testId={testId}/>
    </>
}