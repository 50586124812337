import {CrudForm, FieldProp, MultiFieldProp} from "./common/CrudForm";
import {Organisation, Role, EitherUser} from "../client/UserClient";
import {SelectOption} from "./common/MuiSelect";
import {admin} from "../Routes";
import {Result} from "../client/Result";

export interface CreateFormInitialData {
    roles: Role[],
    organisations: Organisation[]
}

interface WrapperProp{
    initialData: CreateFormInitialData
    onSubmit: (user: EitherUser) => Promise<Result<null>>
}

interface FormUser {
    queryableDataId: string
    name: string
    email: string
    roles: SelectOption[]
    organisation: SelectOption
}

export function CreateUserForm({initialData, onSubmit} : WrapperProp){
    const {roles, organisations} = initialData

    function rolesToOptions(roles: Role[]): SelectOption[] {
        return roles.map(role => ({id: role.id, label: role.name}))
    }

    function organisationToOptions(organisations: Organisation[]): SelectOption[] {
        return organisations.map(organisation => ({id: organisation.id, label: organisation.name}))
    }

    function optionsToRoles(options: SelectOption[]): Role[] {
        return options.map(option => ({id: option.id, name: option.label}))
    }

    function optionsToOrganisations(option: SelectOption): Organisation {
        return  ({id: option.id, name: option.label})
    }
    
    function handleSubmit(formUser: Partial<FormUser>): Promise<Result<null>> {
        if (organisations.length > 1) {
        const {name, email, roles, organisation} = formUser
        if (name && email && organisation) {
            return onSubmit({
                queryableDataId: email,
                name,
                email,
                roles: optionsToRoles(roles || []),
                organisation: optionsToOrganisations(organisation)
            })
        } else {
            throw new Error('Submitting user create without original fields')
        }
    }else{
        const {name, email} = formUser
        if (name && email) {
            return onSubmit({
                queryableDataId: email,
                name,
                email,
            })
        } else {
            throw new Error('Submitting user create without original fields')
        }
    }
    }

    const fullUser : (FieldProp<FormUser> | MultiFieldProp<FormUser>)[]= [
        {key: `name`, label: 'Name', type: 'string', required:true},
        {key: `email`, label: 'Email', type: 'string', required:true},
        {key: `roles`, label: 'Roles', type: 'multiselect', options: rolesToOptions(roles), required:true},
        {key: `organisation`, label: 'Organisation', type: 'select', options: organisationToOptions(organisations), required:true},
    ]

    const simpleUser:  (FieldProp<FormUser> | MultiFieldProp<FormUser>)[] = [
        {key: `name`, label: 'Name', type: 'string', required:true},
        {key: `email`, label: 'Email', type: 'string', required:true},
    ]


    return <CrudForm redirectPath={admin.path} fields={organisations.length > 1 ? fullUser: simpleUser} onSubmit={handleSubmit}/>
}
