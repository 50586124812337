import {DateTime} from "../../time/DateTime";
import {FloatingLabel, Form} from "react-bootstrap";
import React from "react";

interface DurationProps {
    start: DateTime
    end: DateTime
}

export const Duration = ({start, end}: DurationProps)=>{
    return <FloatingLabel label={ 'Duration (mins)'} className="mb-3">
        <Form.Control type={'text'} data-testid="event-duration" value={end.diffMinutes(start)} disabled/>
    </FloatingLabel>
}