import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useEffect, useState } from "react"
import { Alert } from "react-bootstrap"
import { DateTimeRangePicker } from "../common/DateRangePicker";
import { SubmitButton } from "../common/SubmitButton";
import { BuildingInput, StatefulSelect } from "../BuildingInput";
import { Building } from "../BuildingStatus";
import { DateTime } from "../../time/DateTime";
import { Constraint, WithEventId } from "../../client/EventScheduleClient";
import { BanIcon, CrossIcon, TickIcon, TrashIcon } from "../icons/Icons";
import { EventStatus } from "../../model/EventStatus";
import { EventStatusBadge } from "./EventStatusBadge";
import { Duration } from "../common/Duration";
import { BuildingEventConstraints } from "../BuildingEventConstraint";
import { Failure, Result } from "../../client/Result";
import {useDateTimeContext} from "../../DateTimeContext";

export interface CalendarModalEvent {
    building: Building
    type: string
    startTime: DateTime
    endTime: DateTime
}

export type CalendarModalExistingEvent = CalendarModalEvent & WithEventId & {
    title: string
    eventStatus: EventStatus
}

export interface CalendarModalProps {
    buildings: Building[]
    eventCreation: (event: CalendarModalEvent) => Promise<Result<null>>
    retrieveValidationInfo: (event: CalendarModalEvent) => Promise<Constraint>
    eventTypes: string[]
    initialStart: DateTime
    initialEnd: DateTime
    initialShow: boolean
}

export interface EditModalProps {
    buildings: Building[]
    initialEvent: CalendarModalExistingEvent
    retrieveValidationInfo: (event: CalendarModalEvent & WithEventId) => Promise<Constraint>
    eventUpdate: (event: CalendarModalEvent & WithEventId) => Promise<Result<null>>
    eventCancellation: (WithEventId, string) => Promise<Result<null>>
    eventTypes: string[]
    initialShow: boolean
}

export const EditEventModal = ({
    buildings,
    initialEvent,
    retrieveValidationInfo,
    eventUpdate,
    eventCancellation,
    eventTypes,
    initialShow,
}: EditModalProps) => {
    const [show, setShow] = useState<boolean>(initialShow)
    const [eventType, setEventType] = useState<string>(initialEvent.type)
    const [eventStart, setEventStart] = useState<DateTime>(initialEvent.startTime)
    const [eventEnd, setEventEnd] = useState<DateTime>(initialEvent.endTime)

    const [constraint, setConstraint] = useState<Constraint>()
    
    const isUnavailability = (e: string) => e.toLowerCase() == 'unavailable'

    const eventTypeOptions = 
        (isUnavailability(eventType) ? ['Unavailable'] : eventTypes.filter(e => !isUnavailability(e))).map(name => ({ id: name, name }))

    useEffect(() => {
        setEventStart(initialEvent.startTime)
        setEventEnd(initialEvent.endTime)
        initialShow && setShow(initialShow)
    }, [initialEvent.startTime, initialEvent.endTime])

    useEffect(() => {
        setEventType(initialEvent.type)
    }, [initialEvent])

    useEffect(() => {
        if (eventType && eventStart.toMillis() < eventEnd.toMillis()) {
            retrieveValidationInfo({
                eventId: initialEvent.eventId,
                building: initialEvent.building,
                type: eventType,
                startTime: eventStart,
                endTime: eventEnd,
            }).then(setConstraint)
        }
    }, [eventStart, eventEnd])

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dateRangeValid, setDateRangeValid] = useState(false)

    function handleModalSave(): Promise<Result<null>> {
        if (eventType && eventStart && eventEnd /*&& dateRangeValid*/) {
            return eventUpdate({
                eventId: initialEvent.eventId,
                building: initialEvent.building,
                type: eventType,
                startTime: eventStart,
                endTime: eventEnd
            })
        } else {
            return Promise.resolve(new Failure('Missing parameters'))
        }
    }

    function title(prefix, eventTitle) {
        return eventTitle ? `${prefix} "${eventTitle}"` : prefix
    }

    return <EventModal setEventEnd={setEventEnd} setEventType={setEventType}
        eventEnd={eventEnd} eventTypeOptions={eventTypeOptions}
        show={show} setDateRangeValid={setDateRangeValid}
        allowBuildingChange={false} setEventStart={setEventStart}
        setShow={setShow}
        eventStart={eventStart} eventType={eventType}
        handleModalSave={handleModalSave}
        constraint={constraint}
        handleEventCancellation={() => eventCancellation({ eventId: initialEvent.eventId }, initialEvent.type)}
        eventStatus={initialEvent.eventStatus}
        buildings={buildings} building={initialEvent.building}
        title={title('Update Event', initialEvent.title)} showCancelEventButton />
}


export const CreateEventModal = ({
    buildings,
    eventTypes,
    eventCreation,
    retrieveValidationInfo,
    initialStart,
    initialEnd,
    initialShow,
}: CalendarModalProps) => {
    const eventTypeOptions = eventTypes.map(name => ({ id: name, name }))

    const [show, setShow] = useState<boolean>()
    const [eventBuilding, setEventBuilding] = useState<Building>()
    const [eventType, setEventType] = useState<string>()
    const [eventStart, setEventStart] = useState<DateTime>(initialStart)
    const [eventEnd, setEventEnd] = useState<DateTime>(initialEnd)

    const [constraint, setConstraint] = useState<Constraint>()

    useEffect(() => {
        setEventStart(initialStart)
        setEventEnd(initialEnd)
        setEventType(eventTypeOptions.length == 1 ? eventTypeOptions[0].id : undefined)
        setEventBuilding(undefined)
        initialShow && setShow(initialShow)
    }, [initialStart, initialEnd])

    useEffect(() => {
        if (eventBuilding && eventType && eventStart.toMillis() < eventEnd.toMillis()) {
            retrieveValidationInfo({
                building: eventBuilding,
                type: eventType,
                startTime: eventStart,
                endTime: eventEnd
            }).then(setConstraint)
        }
    }, [eventBuilding, eventType, eventStart, eventEnd])

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dateRangeValid, setDateRangeValid] = useState(false)

    function handleModalSave(): Promise<Result<null>> {
        if (eventBuilding && eventType && eventStart && eventEnd /*&& dateRangeValid*/) {
            return eventCreation({ building: eventBuilding, type: eventType, startTime: eventStart, endTime: eventEnd })
        } else {
            return Promise.resolve(new Failure('Missing parameters'))
        }
    }

    return <EventModal setEventEnd={setEventEnd} setEventType={setEventType} eventType={eventType}
        eventEnd={eventEnd} eventTypeOptions={eventTypeOptions}
        show={show} setDateRangeValid={setDateRangeValid} allowBuildingChange={true}
        setEventBuilding={setEventBuilding} setEventStart={setEventStart}
        setShow={setShow}
        eventStart={eventStart}
        handleModalSave={handleModalSave}
        constraint={constraint}
        buildings={buildings}
        title='Create Event'
        building={eventBuilding}
    />
}


interface EventModalProps {
    show
    setShow
    buildings
    allowBuildingChange: boolean
    setEventBuilding?: (Building) => void
    eventType?: string
    setEventType
    eventTypeOptions
    eventStart: DateTime
    eventEnd: DateTime
    setEventEnd
    setEventStart
    setDateRangeValid
    handleModalSave: () => Promise<Result<null>>
    handleEventCancellation?: () => Promise<Result<null>>
    constraint: Constraint | undefined
    eventStatus?: EventStatus
    building?: Building
    title: string
    showCancelEventButton?: boolean
}

const EventModal = ({
    show,
    setShow, buildings, allowBuildingChange,
    setEventBuilding = () => {
        return
    }, eventType, setEventType,
    eventTypeOptions, eventStart, eventEnd,
    setEventEnd, setEventStart,
    setDateRangeValid,
    handleModalSave,
    handleEventCancellation,
    constraint,
    eventStatus,
    building,
    title,
    showCancelEventButton = false
}: EventModalProps) => {
    const now: () => DateTime = useDateTimeContext()

    const [errorMessage, setErrorMessage] = useState<string>()

    useEffect(() => {
        setErrorMessage(undefined)
    }, [show])

    function eventHasEnded(): boolean {
        return eventEnd.toMillis() < now().toMillis()
    }

    async function handleSubmission() {
        setErrorMessage(undefined)
        const result: Result<null> = await handleModalSave()

        switch (result.__typename) {
            case "success": {
                setShow(false)
                break
            }
            case "failure": {
                setErrorMessage(`Failed to save event: ${(result as Failure).error}`)
                break
            }
            default: {
                setErrorMessage(`Unexpected result, please report to #ask-tech`)
                break
            }
        }
    }

    async function handleCancellation() {
        setErrorMessage(undefined)
        const result = await handleEventCancellation!()
        result ?
            result.fold(
                () => setShow(false),
                (error) => setErrorMessage(`Failed to delete the event: ${error}`)
            ) : setErrorMessage(`Unexpected result, please report to #ask-tech`)
    }

    return <Modal show={show} onHide={() => setShow(false)} data-testid={'eventModal'}>
        <Modal.Header>
            <Modal.Title data-testid={'modalTitle'}>{title}</Modal.Title>
            {eventStatus && <EventStatusBadge eventStatus={eventStatus} />}
        </Modal.Header>

        <Modal.Body>
            <BuildingInput className={'mb-3'} disabled={eventHasEnded() || !allowBuildingChange} buildings={buildings} onBuildingChange={setEventBuilding}
                building={building} />
            <StatefulSelect className={'mb-3'} displayName='Event Type' testId='eventType'
                selected={eventType ? { id: eventType, name: eventType } : undefined}
                onChange={(e) => setEventType(e.name)}
                options={eventTypeOptions}
                disabled={eventHasEnded() || (eventTypeOptions.length == 1 && eventType == eventTypeOptions[0].id) }/>
            <DateTimeRangePicker start={eventStart} end={eventEnd}
                onEndChange={e => setEventEnd(e)}
                onStartChange={s => setEventStart(s)}
                setErrorMessage={error => setErrorMessage(error)}
                reportIsValid={valid => setDateRangeValid(valid)}
                disabled={eventHasEnded()}
            />
            <Duration start={eventStart} end={eventEnd} />
            {constraint && eventType?.toLowerCase() != 'unavailable' && <BuildingEventConstraints constraint={constraint} />}
            {errorMessage &&
                <Alert data-testid='errorMessage' variant='danger'>{errorMessage}</Alert>}
        </Modal.Body>

        <Modal.Footer>
            <Button data-testid='closeButton' variant="outline-secondary"
                onClick={() => setShow(false)}><CrossIcon /> Close</Button>
            {showCancelEventButton && !eventHasEnded() &&
                <Button data-testid='cancelEventButton' variant='outline-danger' onClick={handleCancellation} >
                    <TrashIcon /> Cancel Event</Button>}
            {!eventHasEnded() && <SubmitButton dataTestId='submitEventButton'
                           disabled={!(building && eventType && eventStart && eventEnd /*&& dateRangeValid*/)}
                           onSubmit={handleSubmission}><TickIcon/> Save changes</SubmitButton>}
        </Modal.Footer>
    </Modal>
}


interface CancelModalProps {
    show: boolean,
    setShow: (v: boolean) => void,
    eventStart: DateTime,
    eventEnd: DateTime
    building: Building,
    handleEventCancellationRequest: () => void
}

export const CancelEventModal = ({
    show,
    setShow,
    eventStart,
    eventEnd,
    building,
    handleEventCancellationRequest
}: CancelModalProps) => {
    const [requestedCancellation, setRequestedCancellation] = useState<boolean>(false)

    async function handleCancellation() {

        handleEventCancellationRequest()
        setRequestedCancellation(true)
        
        if (requestedCancellation) {
            setShow(false)
        }
    }

    const hasRequested = <Modal.Body  data-testid='request-sent-body' style={{ lineHeight: "1.5" } }>
        The operations team has been notified of your request
        <br />
        In case of an emergency please <a href="mailto:operations@oaktreepower.com" style={{ verticalAlign: "baseline", color: "blue", textDecoration: "underline" }}>
            contact us
        </a>
    </Modal.Body>

    return <Modal show={show} onHide={() => setShow(false)} data-testid={'cancelModal'}>
        <Modal.Header>
            <Modal.Title data-testid={'modalTitle'}>Request Cancellation</Modal.Title>
        </Modal.Header>
        {requestedCancellation ? hasRequested
            :
            <>
                <Modal.Body>
                    <BuildingInput className={'mb-3'} disabled={true} buildings={[building]} onBuildingChange={() => undefined}
                        building={building} />
                    <DateTimeRangePicker start={eventStart} end={eventEnd}
                        onEndChange={() => undefined}
                        onStartChange={() => undefined}
                        setErrorMessage={() => undefined}
                        reportIsValid={() => false}
                        disabled={true}
                    />
                </Modal.Body>
            </>
        }
        <Modal.Footer>
            <Button data-testid='closeButton' variant="outline-secondary"
                onClick={() => setShow(false)}><CrossIcon /> Close</Button>
            {!requestedCancellation && <Button data-testid='requestCancellationButton' variant='outline-danger' onClick={handleCancellation}>
                <BanIcon /> Request Cancellation</Button>
            }
        </Modal.Footer>
    </Modal >
}
