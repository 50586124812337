import React, {useEffect, useState} from "react";
import {SingleSelectTable} from "./Tables";
import {SubmitButton} from "./SubmitButton";
import {EditIcon, PlusIcon, TrashIcon} from '../icons/Icons';
import Button from "react-bootstrap/Button";
import './CrudTable.scss'
import {AlertInfo, UserAlert} from "./UserAlert";
import { Result } from "../../client/Result";
import {GridRowId} from "@mui/x-data-grid";


export type ObjectWithId = object & { queryableDataId: string }
export interface CrudDelete<T> {
    deleteRowById: (queryableDataId: string) => Promise<Result<null>>
    refreshData: () => Promise<T[]>,
}
export interface Crud<T extends ObjectWithId> {
    initialData: T[]
    title: string
    editRowById?: (queryableDataId: string) => Promise<void>
    crudDelete?: CrudDelete<T>
    create?: () => void
    testId?: string
}

export function DisplayOnlyCrudTable<T extends ObjectWithId>({
                                                      initialData,
                                                      title,
                                                      testId
                                                  }: Crud<T>) {


    return <CrudTable initialData={initialData} title={title} testId={testId} />
}


export function CrudTable<T extends ObjectWithId>({
                                                     initialData,
                                                     title,
                                                     editRowById,
                                                      crudDelete,
                                                     create,
                                                     testId
                                                 }: Crud<T>) {

    const [data, setData] = useState<T[]>(initialData)

    const [selectedData, setSelectedData] = useState<T>()

    const [showAlert, setShowAlert] = useState(false)
    const [alertInfo, setAlertInfo] = useState<AlertInfo>()
    const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

    useEffect(()=>{
        setData(initialData)
    }, [JSON.stringify(initialData)])

    function handleEdit() {
        if (selectedData && editRowById) {
            return editRowById(selectedData.queryableDataId)
        } else {
            throw Error('Should not edit without selection')
        }
    }

    async function handleDelete(deleteRowById, refreshData) {
        if (selectedData) {
            const deleted = await deleteRowById(selectedData.queryableDataId)

            await deleted.fold(
                async () => {
                    const refreshed = await refreshData()
                    setSelectionModel([])
                    setData(refreshed)
                    setShowAlert(true)
                    setAlertInfo({message:'Deleted successfully', type:'success'})
                },
                errorMessage => {
                    setAlertInfo({message: errorMessage, type: 'error'})
                    setShowAlert(true)
                    return Promise.resolve()
                }
            )
        } else {
            throw Error('Should not delete without selection')
        }
    }

    return <>
        <UserAlert show={showAlert} setShow={setShowAlert} alertInfo={alertInfo}/>
        <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className='mb-0'>{title}</h5>
            {create? <Button data-testid={'createButton'} variant={'outline-secondary'} onClick={create}><PlusIcon/> Create new</Button>:<></>}
        </div>
        <SingleSelectTable dataTestId={title} data={data} setSelectedData={(t) => setSelectedData(t)} setSelectionModel={setSelectionModel} selectionModel={selectionModel}/>
        {crudDelete?
            <FormButtons onEdit={handleEdit} onDelete={() => handleDelete(crudDelete.deleteRowById, crudDelete.refreshData)} disabled={selectedData == undefined} testId={testId}/> :
            editRowById ? <FormButtons onEdit={handleEdit}  disabled={selectedData == undefined} testId={testId}/>: <></>}
            </>
}

interface FormButtonsProps {
    onDelete?: () => Promise<void>
    onEdit: () => Promise<void>
    disabled: boolean
    testId?: string
}

function FormButtons({onEdit, onDelete, disabled, testId}: FormButtonsProps) {
    const dataTest =(suffix) =>  testId ? `${testId}-${suffix}` : suffix
    return <div className={'formButtons'}>
        {onDelete && <Button data-testid={dataTest('crudTableDelete')} variant='outline-danger' onClick={onDelete} disabled={disabled}>
            <TrashIcon/> Delete</Button>}
        <SubmitButton dataTestId={dataTest('crudTableEdit')} disabled={disabled} onSubmit={onEdit}><EditIcon/> Edit</SubmitButton>
    </div>
}
