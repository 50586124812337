import { CrudTable } from "../common/CrudTable";
import { AsyncComponent, AsyncComponentP } from "../common/AsyncComponent";
import {EitherUser, isFullUser, UserClient} from "../../client/UserClient";
import { createOrgAdmin, createUserAdmin, editOrgAdmin, editUserAdmin } from "../../Routes";
import { Stack } from "react-bootstrap";
import { Permission } from "../../Permission";
import { useNavigate } from "react-router-dom";
import { Entitlement } from "../../Entitlement";
import {useEntitlements} from "../../EntitlementProvider";

interface UserAdminPageProps {
    userClient: UserClient
}

export const UserAdminPage = ({ userClient }: UserAdminPageProps) => {
    const navigate = useNavigate()
    const entitlement: Entitlement = useEntitlements()

    async function loaderFunction() : Promise<EitherUser[]> {
        const users = await userClient.retrieveAllUsers()
        const fullUsers = users.filter(isFullUser)

        if(fullUsers.length>0){
            return fullUsers.map(it => ({ ...it, roles: it.roles.map(role => role.name), organisation: it.organisation.name }))
        }
        return users
    }

    async function orgLoaderFunction() {
        return await userClient.loadAllOrganisations()
            .then(orgs =>
                orgs.map(success => success.map(it => ({ name: it.name, queryableDataId: it.id }))
            ))
    }

    function editUser(email: string): Promise<void> {
        return Promise.resolve(navigate(editUserAdmin.withParams(email)))
    }

    function editOrg(orgId: string): Promise<void> {
        const s = editOrgAdmin.withParams(orgId);
        return Promise.resolve(navigate(s))
    }

    return <Stack gap={3}>
        <AsyncComponent loaderFunction={loaderFunction} dataPropName={'initialData'}>
            <CrudTable initialData={[]}
                       title={'User Admin'}
                       editRowById={editUser}
                       crudDelete={{
                               deleteRowById: userClient.deleteUser.bind(userClient),
                               refreshData: loaderFunction
                           }}
                       create={() => navigate(createUserAdmin.path)}
            />
        </AsyncComponent>
        {entitlement?.has(Permission.WRITE_ORG) ?
            <AsyncComponentP loaderFunction={orgLoaderFunction} dataPropName={'initialData'}>
                <CrudTable initialData={[]}
                    title={'Org Admin'}
                    editRowById={editOrg}
                           crudDelete={{
                               deleteRowById: org => userClient.deleteOrg(org),
                               refreshData: () => orgLoaderFunction().then(i => i.fold(i => i, () => []))
                           }}
                    create={() => navigate(createOrgAdmin.path)}
                    testId="orgTable"
                />
            </AsyncComponentP> : <></>}
    </Stack>
}
