import FormGroup from "react-bootstrap/FormGroup";
import {DatePicker} from "./DatePicker";
import React, {useEffect, useRef, useState} from "react";
import {DateTime} from "../../time/DateTime";
import {DateTimePicker} from "@mui/x-date-pickers";

interface DateRangePickerProps {
    setErrorMessage
    start?: string
    end?: string
    onStartChange
    onEndChange
    reportIsValid
    includeTime?: boolean
    disabled?: boolean
    testId?: string
}

interface DateTimeRangePickerProps {
    setErrorMessage
    start?: DateTime
    end?: DateTime
    onStartChange: (DateTime) => void
    onEndChange: (DateTime) => void
    reportIsValid
    disabled?: boolean
}

interface TypedDateRangePickerProps {
    setErrorMessage
    start?: DateTime
    end?: DateTime
    onStartChange: (DateTime) => void
    onEndChange: (DateTime) => void
    reportIsValid,
    disabled?: boolean
}

export const DateTimeRangePicker = ({
                                        setErrorMessage,
                                        start,
                                        end,
                                        onStartChange,
                                        onEndChange,
                                        reportIsValid,
                                        disabled = false
                                    }: DateTimeRangePickerProps) => {

    const [startDate, setStartDate] = start ? useState<DateTime>(start) : useState<DateTime>()
    const [endDate, setEndDate] = end ? useState<DateTime>(end) : useState<DateTime>()

    useEffect(() => {
        start && setStartDate(start)
        end && setEndDate(end)
    }, [start, end])

    const startRef = useRef<HTMLInputElement>(null)
    const endRef = useRef<HTMLInputElement>(null)

    function handleChangeStartDate(e) {
        setErrorMessage(undefined)
        const newStartDate = DateTime.fromRaw(e)
        setStartDate(newStartDate)
        onStartChange(newStartDate)
        endDate && dateRangeCustomValidityCheck(newStartDate, endDate)
    }

    function handleChangeEndDate(e) {
        setErrorMessage(undefined)
        const newEndDate = DateTime.fromRaw(e)
        setEndDate(newEndDate)
        onEndChange(newEndDate)
        startDate && dateRangeCustomValidityCheck(startDate, newEndDate)
    }

    function dateRangeCustomValidityCheck(startDate: DateTime, endDate: DateTime) {
        if (startDate.toMillis() > endDate.toMillis()) {
            startRef.current?.setCustomValidity('Start date must be the same or before end date')
            startRef.current?.reportValidity()
            endRef.current?.setCustomValidity('End date must be the same or after start date')
            endRef.current?.reportValidity()
            reportIsValid(false)
        } else {
            startRef.current?.setCustomValidity('')
            endRef.current?.setCustomValidity('')
            reportIsValid(true)
        }
    }

    return <FormGroup className={'row date-time-range-group'}>
        <DateTimePicker className={'col'} data-testid='startDate' value={startDate?.raw()} label='Start Date' onChange={handleChangeStartDate} disabled={disabled} />
        <DateTimePicker className={'col'} data-testid='endDate' value={endDate?.raw()} minDateTime={startDate?.raw()} label='End Date' onChange={handleChangeEndDate} disabled={disabled} />
    </FormGroup>
}

export const TypedDateRangePicker = ({
                                         setErrorMessage,
                                         start,
                                         end,
                                         onStartChange,
                                         onEndChange,
                                         reportIsValid
                                     }: TypedDateRangePickerProps) => {
    return <DateRangePicker end={end?.toISODate()}
                            start={start?.toISODate()}
                            onEndChange={e => onEndChange(DateTime.fromISODateString(e))}
                            onStartChange={e => onStartChange(DateTime.fromISODateString(e))}
                            reportIsValid={reportIsValid} setErrorMessage={setErrorMessage} />
}

export const DateRangePicker = ({
                                    setErrorMessage,
                                    start,
                                    end,
                                    onStartChange,
                                    onEndChange,
                                    reportIsValid,
                                    disabled = false,
                                    testId = '',
                                }: DateRangePickerProps) => {
    const [startDate, setStartDate] = useState<string>(start ?? '')
    const [endDate, setEndDate] = useState<string>(end ?? '')

    useEffect(() => {
        start && setStartDate(start)
        end && setEndDate(end)
    }, [start, end])

    const startRef = useRef<HTMLInputElement>(null)
    const endRef = useRef<HTMLInputElement>(null)

    function handleChangeStartDate(e) {
        setErrorMessage(undefined)
        const newStartDate = e.target.value;
        setStartDate(newStartDate)
        onStartChange(newStartDate)
        endDate && dateRangeCustomValidityCheck(newStartDate, endDate)
    }

    function handleChangeEndDate(e) {
        setErrorMessage(undefined)
        const newEndDate = e.target.value;
        setEndDate(newEndDate)
        onEndChange(newEndDate)
        startDate && dateRangeCustomValidityCheck(startDate, newEndDate)
    }

    function dateRangeCustomValidityCheck(start: string, end: string) {
        const startDate: DateTime | string = DateTime.FromISOOrError(start)
        const endDate: DateTime | string = DateTime.FromISOOrError(end)

        const startType = typeof startDate === 'string';
        const endType = typeof endDate === 'string';

        if (startType || endType) {
            reportIsValid(false)

            if (startType) {
                startRef.current?.setCustomValidity('Start date must be the same or before end date')
                startRef.current?.reportValidity()
            }
            if (endType) {
                endRef.current?.setCustomValidity('End date must be the same or after start date')
                endRef.current?.reportValidity()
            }
        } else if (startDate.toMillis() > endDate.toMillis()) {
                startRef.current?.setCustomValidity('Start date must be the same or before end date')
                startRef.current?.reportValidity()
                endRef.current?.setCustomValidity('End date must be the same or after start date')
                endRef.current?.reportValidity()
                reportIsValid(false)
            } else {
                startRef.current?.setCustomValidity('')
                endRef.current?.setCustomValidity('')
                reportIsValid(true)
            }

        }

        return <FormGroup className={'row'}>
            <DatePicker includeTime={false} className={'col'} dateRef={startRef} label={'Start Date'}
                        testId={`startDate${testId}`} value={startDate} onChange={handleChangeStartDate} disabled={disabled}/>
            <DatePicker includeTime={false} className={'col'} minDate={startDate} dateRef={endRef}
                        label={'End Date'} testId={`endDate${testId}`} value={endDate} onChange={handleChangeEndDate} disabled={disabled}/>
        </FormGroup>
    }
