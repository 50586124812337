import {DeviceClient, DeviceRegistration} from "../../client/DeviceClient";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {DeviceRegistrationDisplayCard} from "../DevicePreparation";
import * as React from "react";

export interface DeviceConfigurationPageProps {
    deviceClient: DeviceClient
}

export const DeviceConfigurationPage =({deviceClient}: DeviceConfigurationPageProps) => {
    const { id } = useParams()
    const [deviceRegistration, setDeviceRegistration] = useState<DeviceRegistration>()

    useEffect(()=>{
        if(id) {
            deviceClient.retrieveDeviceKey(id).then( deviceRegistration =>
                setDeviceRegistration(deviceRegistration)
            )
        }
    },[id, deviceClient])

    return  <div>
        {deviceRegistration ? <DeviceRegistrationDisplayCard deviceRegistration={deviceRegistration} title={deviceRegistration?.deviceId}/> : <>Loading Device Config</>}
    </div>
}