import {useEffect, useState} from "react";
import {Autocomplete, MenuItem, TextField} from "@mui/material";
import {TickIcon} from "../icons/Icons";
import Box from "@mui/material/Box";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import {default as MuiSelect, SelectChangeEvent} from '@mui/material/Select';

export interface SelectOption { label: string, id: string }

export interface MultiSelectProps{
    options: SelectOption[]
    label: string
    onChange: (options  : SelectOption[])=>void
    'data-testid'?: string
    id?: string
    initialValue?: SelectOption[]
    minWidth?:number
    required?: boolean
    disabled?: boolean
}

export interface SelectProps<T extends SelectOption>{
    options: T[]
    label: string
    onChange: (options  : T|undefined)=>void
    'data-testid'?: string
    id?: string
    initialValue?: T
    minWidth?:number
    required?: boolean
    disabled?: boolean
    className?: string
}


export function Select<T extends SelectOption>({initialValue, options, label, onChange, 'data-testid': dataTestId, id, required, disabled, minWidth, className}:SelectProps<T>) {
    const [value, setValue] = useState<T | ''>(initialValue??'')

    useEffect(()=>{
        setValue(initialValue??'')
    },[initialValue])

    const handleChange = (event: SelectChangeEvent) => {
       const selected = options.find(it => it.id == event.target.value)
        onChange(selected)
        setValue(selected??'');
    }

    return (
        <Box sx={{ minWidth: minWidth ??120 }} className={className}>
            <FormControl fullWidth>
                <InputLabel id="select-label">{label}</InputLabel>
                <MuiSelect
                    data-testid={dataTestId}
                    id={id}
                    value={value === '' ? value : (value?.id ?? '')}
                    label={label}
                    required={required}
                    disabled={disabled}
                    onChange={handleChange}
                >
                    {options.map((it, i) => <MenuItem key={`option-${i}`} value={it.id}>{it.label}</MenuItem>)}
                </MuiSelect>
            </FormControl>
        </Box>
    );
}

export default function MultiSelect({initialValue, options, label, onChange, 'data-testid': dataTestId, id, required, disabled, minWidth}:MultiSelectProps) {
    const [value, setValue] = useState<SelectOption[]>(initialValue??[])
    return (
        <Autocomplete
            disabled={disabled}
            value={value}
            id={id}
            data-testid={dataTestId}
            sx={{minWidth: minWidth?? 'auto' }}
            multiple
            options={options}
            onChange={(_, v) => {
                setValue(v)
                onChange(v)
            }
        }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disableCloseOnSelect
            renderInput={(params) => (
                <TextField
                    required={required}
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        required: value.length === 0
                    }}
                    variant="outlined"
                    label={label}
                    placeholder={`Select ${label}`}
                />
            )}
            renderOption={(props, option, { selected }) => (
                <MenuItem
                    {...props}
                    key={option.id}
                    value={option.label}
                    sx={{ justifyContent: "space-between", width: 'auto'}}
                >
                    {option.label}
                    {selected ? <TickIcon/> : null}
                </MenuItem>
            )}
        />
    );
}